import { AfterViewInit, Component, Input, OnInit} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MiaActionPerformerService } from 'src/mia-components/public-api';

import { Provider } from '../provider';

@Component({
  selector: 'app-provider-network-map',
  templateUrl: './provider-network-map.page.html',
  styleUrls: ['./provider-network-map.page.scss'],
})
export class ProviderNetworkMapPage implements OnInit, AfterViewInit {
  static ASSETS_ICON_URL = '/assets/icon/pin';
  static ICON_SIZE = 50;

  @Input()
  userPosition?: google.maps.MapOptions['center'];

  @Input()
  providers: Provider[];

  providerDesciptionFields: Array<{ label: string, providerKey: keyof Provider }> = [
    {
      label: 'Descontos',
      providerKey: 'DESCUENTO OTORGADO'
    },
    {
      label: 'Tipo de Serviço',
      providerKey: 'TIPO DE SERVICIO'
    }/*,
    {
      label: 'Horário',
      providerKey: 'HORARIO'
    }*/
  ];

  private iconInstancesCache = new Map<string, google.maps.Icon>();

  constructor(
    private $modalCtrl: ModalController,
    private $miaActionPerformer: MiaActionPerformerService) { }

  ngOnInit() {
    console.log(this.providers);
  }

  ngAfterViewInit() {
  }

  makeCall(provider: Provider) {
    // TODO: Get provider phone number
    this.$miaActionPerformer.perform({
      type: 'link',
      link: 'tel:{phone}',
      linkVars: {
        phone: provider['TELEFONO CENTRAL TELEFONICA']
      },
      tracked: 'tel'
    });
  }

  closeModal() {
    this.$modalCtrl.dismiss();
  }

  resolvePinIcon(iconFilename: string) {
    const iconUrl = `${ProviderNetworkMapPage.ASSETS_ICON_URL}/${iconFilename}`
    let icon: google.maps.Icon = this.iconInstancesCache.get(iconUrl);

    if (!icon) {
      const size = new google.maps.Size(
        ProviderNetworkMapPage.ICON_SIZE,
        ProviderNetworkMapPage.ICON_SIZE
      );

      this.iconInstancesCache.set(
        iconUrl,
        icon = {
          url: iconUrl,
          scaledSize: size,
          size
        }
      )
    }

    return icon
  }
}
