import {
  Component,
  Input,
  OnInit
} from '@angular/core';
import { ModalController } from '@ionic/angular';

import { ContactButton } from '../contact';
import { MiaActionPerformerService } from '../mia-action-performer.service';

@Component({
  selector: 'mia-contact-modal',
  templateUrl: './mia-contact-modal.component.html',
  styleUrls: ['./mia-contact-modal.component.scss']
})
export class MiaContactModalComponent implements OnInit {

  @Input()
  title = 'mia.contactModal.title';

  @Input()
  buttons: ContactButton[] = [
    {
      text: 'mia.contactModal.itemTextByAction.whatsapp',
      icon: 'grupo_260',
      action: 'whatsapp'
    },
    {
      text: 'mia.contactModal.itemTextByAction.tel',
      icon: 'grupo_250',
      action: 'tel'
    }
  ];

  @Input()
  extraContent: string;

  constructor(
    private $modalCtrl: ModalController,
    private $miaActionPerformer: MiaActionPerformerService) { }

  ngOnInit(): void {
  }

  onBtnClick(btn: ContactButton): void {
    this.$miaActionPerformer.perform(btn.action);
  }

  closeModal(): void {
    this.$modalCtrl.dismiss();
  }
}
