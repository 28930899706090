export interface MiaLangDefinition {
  historyModal: {
    title: string;
    itemTextByType: {
      tel: string;
      whatsapp: string;
      assistance: string;
    };
  };
  contactModal: {
    title: string;
    itemTextByAction: {
      tel: string;
      whatsapp: string;
    }
  };
  geolocationModal: {
    title: string;
    propertyInputPlaceholder: string;
    propertyInputTitle: string;
    btnsTitle: string;
    cancelBtn: string;
    okBtn: string;
    calculatingGPSText: string;
    calculatingGPSErrorText: string;
  };
  geolocationOptionsPopover: {
    message: string;
    retryBtn: string;
    manualBtn: string;
  };
  bottomMenu: {
    historyBtn: string;
  };
}

export function defineMiaLang(langDefinition: MiaLangDefinition): { mia: MiaLangDefinition } {
  return {
    mia: langDefinition
  };
}
