import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  Inject
} from '@angular/core';
import { IonButton } from '@ionic/angular';

import {
  ConfigToken,
  Config
} from '../config-token';

@Component({
  selector: 'mia-header',
  templateUrl: './mia-header.component.html',
  styleUrls: ['./mia-header.component.scss']
})
export class MiaHeaderComponent implements OnInit {

  @Input()
  showBack = true;

  @Input()
  backColor: IonButton['color'] = 'primary';

  @Input()
  logo: string;

  @Output()
  back = new EventEmitter<void>();

  @Output()
  home = new EventEmitter<void>();

  get usedLogo() {
    return (
      this.logo ||
      this.$config.logo
    );
  }

  constructor(
    @Inject(ConfigToken)
    private $config: Config) { }

  ngOnInit(): void {
  }

  emitBack(): void {
    this.back.emit();
  }

  emitHome(): void {
    this.home.emit();
  }

  async goHome(): Promise<void> {
    const $ionModals = document.querySelectorAll('ion-modal');

    // Dismiss all modals
    // tslint:disable-next-line
    for (let i = 0; i < $ionModals.length; i++) {
      await $ionModals[i].dismiss(
        null,
        'gesture' /* Use gesture so animations can be skipped */
      );
    }

    this.emitBack();
  }
}
