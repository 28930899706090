import { Injectable } from '@angular/core';

import { StopWatchPosition } from './geolocation';
import { MiaLoggerService } from './mia-logger.service';

/**
 * NOTE: This is just a service with an "API-enchancer" or API wrapper for
 * native geolocation brower's API
 */

@Injectable({
  providedIn: 'root'
})
export class MiaGeolocationService {

  geolocation = navigator.geolocation;

  constructor(
    private $miaLogger: MiaLoggerService) { }

  getCurrentPosition(options?: PositionOptions): Promise<Parameters<PositionCallback>[0]> {
    return new Promise((resolve, reject) => {
      this.geolocation.getCurrentPosition(
        resolve,
        reject,
        options
      );
    });
  }

  watchPosition(
    watcher: PositionCallback,
    options?: PositionOptions,
    errorCb: PositionErrorCallback = error => this.$miaLogger.error('Error watching position:', error)): StopWatchPosition {
    const watchId = this.geolocation.watchPosition(
      watcher,
      errorCb,
      options
    );

    return () => {
      this.geolocation.clearWatch(watchId);
    };
  }
}
