import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { PersonalDataService } from 'src/app/personal-data.service';

import {
  DeepMenuItem,
  DeepMenuItemsFilter
} from '../deep-menu';
import { MiaActionPerformerService } from '../mia-action-performer.service';

const defaultFilterItems: DeepMenuItemsFilter = items => items;

@Component({
  selector: 'mia-deep-menu',
  templateUrl: './mia-deep-menu.component.html',
  styleUrls: ['./mia-deep-menu.component.scss']
})
export class MiaDeepMenuComponent implements OnInit, OnChanges {
  @Input()
  title: string;

  @Input()
  items: DeepMenuItem[];

  @Input()
  filterItems: DeepMenuItemsFilter = defaultFilterItems;

  @Output()
  back = new EventEmitter();

  currentTitle: string;
  currentItems: DeepMenuItem[];

  /**
   * Internal navigation path stack
   */
  pathStack: DeepMenuItem[] = [];

  constructor(
    private $miaActionPerformer: MiaActionPerformerService) { }

  ngOnInit(): void {
    this.currentTitle = this.title;
    this.currentItems = this.items;
  }

  ngOnChanges({ title, items }: SimpleChanges): void {
    this.currentTitle = title.currentValue;
    this.currentItems = items.currentValue;
  }

  async onItemSelect(item: DeepMenuItem): Promise<void> {
    if (item.action) {
      this.$miaActionPerformer.perform(item.action);
    } else if (item.submenu) {
      this.currentTitle = item.title;
      this.currentItems = await this.filterItems(item.submenu);
    }
  }

  goBack(): void {
    if (!this.pathStack.length) {
      return this.back.emit();
    }

    const nextItem = this.pathStack.pop();

    this.currentTitle = nextItem.title;
    this.currentItems = nextItem.submenu;
  }

  async goHome(): Promise<void> {
    const $ionModals = document.querySelectorAll('ion-modal');

    // Dismiss all modals
    // tslint:disable-next-line
    for (let i = 0; i < $ionModals.length; i++) {
      await $ionModals[i].dismiss(
        null,
        'gesture' /* Use gesture so animations can be skipped */
      );
    }

    this.emitBack();
  }

  emitBack(): void {
    this.back.emit();
  }
}
