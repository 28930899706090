import {
  Inject,
  Injectable,
} from '@angular/core';

import { Action } from './action';
import { Config, ConfigToken } from './config-token';
import { MiaHistoryService } from './mia-history.service';
import { MiaModalResolverService } from './mia-modal-resolver.service';
import { MiaTemplateProcessorService } from './mia-template-processor.service';
import { PharmaClientService } from 'src/app/pharma.service';

@Injectable({
  providedIn: 'root'
})

export class MiaActionPerformerService {
  constructor(
    @Inject(ConfigToken)
    private $config: Config,
    private $miaModalResolver: MiaModalResolverService,
    private $miaTemplateProcessor: MiaTemplateProcessorService,
    private $miaHistory: MiaHistoryService,
    private $pharma: PharmaClientService,
  ) { }

  perform(action: Action): void {
    if (typeof action === 'string') {
      const actionKey = action;
      action = this.$config.actions[actionKey];

      if (!action) {
        throw new Error(`Unknown action: ${actionKey}`);
      }
    }

    switch (action.type) {
      case 'modal':
        this.$miaModalResolver.open(action.modal, {
          componentProps: action.props
        });
        break;

      case 'link':
        const link = this.$miaTemplateProcessor.process(
          action.link,
          action.linkVars ?? {}
        );

        openPopupFromAnchor(link);

        if (action.tracked) {
          this.$miaHistory.addHistoryEntry(
            action.tracked,
            action.linkVars
          );
        }
        break;

      case 'phygital':
        this.$pharma.phygitalAuthenticate().toPromise().then((result) => {
          if (result) {
            let token = result['data']['token']['accessToken'];
            this.$pharma.phygitalToken(token, action["props"].cpf).toPromise().then((resultToken) => {
              if (resultToken) {
                let key = resultToken['data']['key'];
                let url = 'https://epharmastore.epharma.com.br/?v=1&key=' + key;
                openPopupFromAnchor(url);
              }
            });
          }
        });

        break;
    }
  }
}

function openPopupFromAnchor(href) {
  const anchor = document.createElement('a');
  anchor.href = href;
  anchor.target = '_blank';
  anchor.click();
}
