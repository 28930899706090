import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

import { AssetUriResolverService } from './asset-uri-resolver.service';
import { MiaLoggerService } from './mia-logger.service';

@Injectable({
  providedIn: 'root'
})
export class MiaProvidersLoaderService<Provider extends object> {

  providersPromise: Promise<Provider[]>;

  constructor(
    private $assetUriResolver: AssetUriResolverService,
    private $miaLogger: MiaLoggerService) {
    this.providersPromise = this._loadProviders(this.$assetUriResolver.resolveProvidersFileUri());
  }

  async getProvidersByFieldConditions<K extends keyof Provider, V extends string|RegExp>(conditions: Partial<Record<K, V>>): Promise<Provider[]> {
    const providers = await this.providersPromise;
    const conditionKeys = Object.keys(conditions) as K[];

    return providers.filter(provider => (
      conditionKeys.every(conditionKey => {
        const condition: V = conditions[conditionKey];
        const providerValue = String(provider[conditionKey]);

        return typeof condition === 'string'
          ? String(providerValue).trim() === condition.trim()
          : (condition as RegExp).test(providerValue);
      })
    ));
  }

  async getByIndex(index: number): Promise<Provider> {
    return (await this.providersPromise)[index];
  }

  async getUniqueValuesByKey<K extends keyof Provider, R extends Provider[K]>(key: K): Promise<R[]> {
    const providers = await this.providersPromise;

    return [
      ...new Set<R>(
        providers.map(provider => provider[key]) as any
      )
    ];
  }

  async getUniqueValuesByValueAndDependantKey<K extends keyof Provider,V extends Provider[K],D extends keyof Provider,R extends Provider[D]>(key: K, value: V, dependantKey: D): Promise<R[]> {
    const providers = await this.providersPromise;

    return [
      ...new Set<R>(
        providers
          .filter(provider => provider[key] === value)
          .map(provider => provider[dependantKey]) as any
      )
    ];
  }

  private async _loadProviders(fileUri: string): Promise<Provider[]> {
    try {
      const response = await fetch(fileUri);
      const data = new Uint8Array(await response.arrayBuffer());
      const workbook = XLSX.read(data, { type: 'array' });
      const [firstSheetName] = workbook.SheetNames;

      const datos: Provider[] = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheetName]);
      return datos;

    } catch (error) {
      this.$miaLogger.error('An error ocurred while loading and parsing providers:', error);

      return [];
    }
  }
}
