// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  countryId: 'BR',
  apiClientes: {
    url: 'https://api.mia-assistance.com/maip_api_clients_be-web',
    url_auth: 'https://auth.mia-assistance.com/oauth2/token',
    url_Assistance: 'https://api.mia-assistance.com/maip_api_assistances_be-web'
  },
  "br": {
    "client_id": '2gdmcgql9pgq21pc29jd2235ko',
    "client_secret": 'g1cdkv2ebmogjkghufn6qmsfj7advrrt9vtmqm3i24jfvbujlto'
  },
  "fontes": {
    "client_id": '2a940sghq8ksfgibmjqhj1oo9b',
    "client_secret": '1ho1u33loq026n0i49ib3u1jisjoc26n1m62k83uqr7dtbnl3248'
  },
  "nfs": {
    "client_id": '3knavdvhhhgri9ti30vck7vn9',
    "client_secret": '1tp8mdemaee50iv6i0lt3jj9jq12s5cok1jg52dtta4hkp5ol44s'
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
