import {
  Component,
  OnInit,
  Output,
  EventEmitter
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import {
  DynamicFormField,
  DynamicFormFieldTypes,
  ImageCarouselItem,
  MiaModalResolverService
} from 'src/mia-components/public-api';

import { PersonalDataService } from '../personal-data.service';
import { MiaActionPerformerService } from 'src/mia-components/public-api';
import { ModalService } from '../modal.service';
import { ImageMenuItem } from '../../mia-components/lib/image-menu';

@Component({
  selector: 'app-personal-data-form',
  templateUrl: './personal-data-form.page.html',
  styleUrls: ['./personal-data-form.page.scss'],
})
export class PersonalDataFormPage implements OnInit {
  items: any;

  @Output() menuSelect = new EventEmitter<ImageMenuItem>();

  form = new FormGroup({});
  carouselItems: [];
  // carouselItems: ImageCarouselItem[] = [
  //   {
  //     image: ['datos_Personales', 'png']
  //   }
  // ]

  termsAndConditionsModalContent = [
    "O Grupo MAPFRE respeita e cumpre as exigências da LGPD, Lei nº 13.709/2018 que trata da proteção de dados pessoais. Tratamos seus dados para procedimentos preliminares, execução de contrato relacionado ao produto contratado. Saiba mais sobre a nossa Política de Privacidade <a href='https://www.mapfre.com.br/politica-privacidade/'>https://www.mapfre.com.br/politica-privacidade/</a>"
  ];

  termsAndConditionsTextModalTitle = "PRIVACIDADE";

  fields: DynamicFormField[] = [
    {
      label: 'personalDataModal.fields.cpf',
      name: 'documento',
      type: DynamicFormFieldTypes.number,
      rules: {
        required: true
      }
    },
    {
      label: 'personalDataModal.fields.firstName',
      name: 'firstName',
      type: DynamicFormFieldTypes.text,
      rules: {
        required: true
      }
    },
    {
      label: 'personalDataModal.fields.fatherLastName',
      name: 'fatherLastName',
      type: DynamicFormFieldTypes.text,
      rules: {
        required: true
      }
    },
    // {
    //   label: 'personalDataModal.fields.motherLastName',
    //   name: 'motherLastName',
    //   type: DynamicFormFieldTypes.text
    // },
    {
      label: 'personalDataModal.fields.email',
      name: 'email',
      type: DynamicFormFieldTypes.email,
      rules: {
        email: true
      }
    },
    {
      label: 'personalDataModal.fields.phoneNumber',
      name: 'phoneNumber',
      type: DynamicFormFieldTypes.number,
      rules: {
        required: true,
        minLength: 10,
        maxLength: 11
      }
    }
  ];

  constructor(
    private $modalCtrl: ModalController,
    private $personalData: PersonalDataService,
    private $miaModalResolver: MiaModalResolverService,
    private $miaActionPerformer: MiaActionPerformerService,
    private $modalService: ModalService,) { }

  ngOnInit() {
    this._loadInitialData();
  }

  onSaveData() {
    this.$personalData.addEntry(this.form.value);
    if (this.$modalService.items.action["modal2"]) {
      this.items = this.$modalService.items;
      this.$modalService.items = [];
      this.onMenuSelect(this.items);

    } else {
      this.$miaModalResolver.open('cartonDigital');
      //assistancesMenu
      this.closeModal();
    }
  }

  closeModal() {
    this.$modalCtrl.dismiss();
  }

  private async _loadInitialData() {
    if (!await this.$personalData.hasEntries()) {
      return;
    }

    const { id, ...personalData } = await this.$personalData.getLastEntry();

    this.form.setValue(personalData);
  }

  emitMenuSelect(item: ImageMenuItem): void {
    this.menuSelect.emit(item);
  }

  onMenuSelect(item: ImageMenuItem): void {
    let modal = "";
    if (item.action["modal2"]) {
      modal = item.action["modal"];
      item.action["modal"] = item.action["modal2"];
    }

    this.$miaActionPerformer.perform(item.action);
    item.action["modal"] = modal;
    this.emitMenuSelect(item);
  }
}
