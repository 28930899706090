import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import contract from '../config/api/contract.json';

interface TokenResult {
  exp: number;
  token: string;
  refreshToken: string;
}

interface AdditionalInfoResult {
  data: {
    infadicionales: Array<{
      codatribu: string;
      desatribu: string;
      formato: 'ALFNUM' | 'NUMERO';
      valores: string[];
      validar: boolean;
    }>;
    mensajeError: any;
  };
}

export interface Contract {
  codclient: string;
  codcontra: string;
  coddivisa: string;
  descontra: string;
  suplem: number;
}

interface ContractsResult {
  data: {
    contratos: Contract[];
  };
}

export interface ServiceContract extends Omit<Contract, 'coddivisa' | 'descontra'> {
  // ama: number;
  codcausa: string;
  codgarant: string;
  codcobert: string;
  codriesgo: number;
  codservicio: number;
}

@Injectable({
  providedIn: 'root'
})
export class MaiViewerClientService {

  credentials = {
    url: 'https://api.mapfre.com/srv/maiviewer',
    user: 'MAICLIENT',
    pass: 'M$1client'
  };

  constructor(
    private $http: HttpClient) { }

  login() {
    const authorizationPair = btoa(`${this.credentials.user}:${this.credentials.pass}`);
    const headers = {
      Authorization: `Basic ${authorizationPair}`/*,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-credentials': 'true',
      'Access-Control-Allow-header': 'origin, x-requested-with, accept',
      'Content-Type': 'application/json;charset=UTF-8'*/
    };

    const body = {
      // TODO
      country_code: 'MX'
    };

    return this.$http
      .post<TokenResult>(this._getURL('login'), body, { headers })
      .toPromise();
  }

  getAdditionalInfo(token: string) {
    const headers = {
      Authorization: `Bearer ${token}`
    };

    return this.$http
      .get<AdditionalInfoResult>(
        this._getURL('api/infadicional/'),
        {
          headers,
          params: {
            ...contract
          } as Partial<ServiceContract> as any
        }
      )
      .toPromise();
  }
/*
  getContracts(token: string) {
    const headers = {
      Authorization: `Bearer ${token}`
    };

    return this.$http
      .get<ContractsResult>(
        this._getURL('api/contrato/'),
        {
          headers,
          params: { cliente: contracts.cliente, usuario: contracts.usuario }
        }
      )
      .toPromise();
  }
*/
  private _getURL(path: string) {
    return `${this.credentials.url}/${path}`;
  }
}
