import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { MiaComponentsModule } from 'src/mia-components/public-api';
import {
  HttpClientModule,
  HttpClient
} from '@angular/common/http';
import {
  TranslateModule,
  TranslateLoader
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AgmCoreModule } from '@agm/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { PersonalDataFormPage } from './personal-data-form/personal-data-form.page';
import { PersonalDataService } from './personal-data.service';
import { AssistancesMenuPage } from './assistances-menu/assistances-menu.page';
import { SaludpreventivaMenuPage } from './saludpreventiva-menu/saludpreventiva-menu.page'
import { VantagensEmSaudeMenuPage } from './vantagens-em-saude-menu/vantagens-em-saude-menu.page';
import { AmbulanceDispatchPage } from './ambulance-dispatch/ambulance-dispatch.page';
import { TelephoneMedicalConsultationPage } from './telephone-medical-consultation/telephone-medical-consultation.page';
import { HomeDoctorPage } from './home-doctor/home-doctor.page';
import { ProviderNetworkMapPage } from './provider-network-map/provider-network-map.page';
import { ProviderNetworkPage } from './provider-network/provider-network.page';
import { PharmaPage } from './pharma/pharma.page';
import { TelemedicinaPage } from './telemedicina/telemedicina.page';
import { MeetingDoctorsPage } from './meeting-doctors/meeting-doctors.page';
import { CartonDigitalPage } from './carton-digital/carton-digital.page';
import { TermsConditionsPage } from './terms-conditions/terms-conditions.page';
import { RedeCredenciadaPage } from './rede-credenciada/rede-credenciada.page';
import pagesConfig from '../config/pages.json';

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/');
}

registerLocaleData(localeEs);

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: 'md'
    }),
    AppRoutingModule,
    HttpClientModule,
    AgmCoreModule.forRoot({
      // NOTE: Initialized on index.html
      /*apiKey: 'AIzaSyB7_2etUPei2P3900nj_mEOFZHowtSnL_o',
      libraries: ['places']*/
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'es',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    MiaComponentsModule.forRoot({
      logo: 'logo-azul',
      assetsUri: './assets',
      modals: {
        personalData: PersonalDataFormPage,
        assistancesMenu: AssistancesMenuPage,
        saludpreventivaMenu: SaludpreventivaMenuPage,
        vantagensEmSaudeMenu: VantagensEmSaudeMenuPage,
        ambulanceDispatch: AmbulanceDispatchPage,
        telephoneMedicalConsultation: TelephoneMedicalConsultationPage,
        homeDoctor: HomeDoctorPage,
        providerNetwork: ProviderNetworkPage,
        providerNetworkMap: ProviderNetworkMapPage,
        pharma: PharmaPage,
        telemedicina: TelemedicinaPage,
        redeCredenciada: RedeCredenciadaPage,
        meetingDoctorsPage: MeetingDoctorsPage,
        cartonDigital: CartonDigitalPage,
        TermsConditions: TermsConditionsPage
      },
      db: {
        name: 'mia-health',
        version: 1,
        objectStoresMeta: [
          PersonalDataService.config
        ]
      },
      actions: {
        telemedicineLink: {
          type: 'link',
          link: pagesConfig.telemedicine.link
        },
        homeDoctorAssistErrorTel: {
          type: 'link',
          link: 'tel:{tel}',
          linkVars: {
            tel: pagesConfig.homeDoctor.assistErrorTel
          }
        }
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
