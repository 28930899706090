import {
  Component,
  Input,
  OnInit
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ImageCarouselItem } from '../image-carousel';
import { MiaTemplateProcessorService } from '../mia-template-processor.service';

@Component({
  selector: 'mia-image-carousel',
  templateUrl: './mia-image-carousel.component.html',
  styleUrls: ['./mia-image-carousel.component.scss']
})
export class MiaImageCarouselComponent implements OnInit {

  @Input()
  items: ImageCarouselItem[];

  private templateHTMLCache = new Map<string, Promise<string>>();

  constructor(
    private $miaTemplateProcessor: MiaTemplateProcessorService,
    private $translate: TranslateService) { }

  ngOnInit(): void {
  }

  getTemplateHTML(item: ImageCarouselItem): Promise<string> {
    const keys = [item.text, item.strong];
    const keyStr = keys.join('');

    let promise = this.templateHTMLCache.get(keyStr);

    if (promise) {
      return promise;
    }

    promise = new Promise((resolve, reject) => {
      this.$translate
        .get(keys)
        .subscribe(
          result => {
            resolve(
              this.$miaTemplateProcessor.process(
                result[item.text],
                {
                  strong: `<strong>${result[item.strong]}</strong>`
                }
              )
            );
          },
          reject
        );
    });

    this.templateHTMLCache.set(keyStr, promise);
    return promise;
  }
}
