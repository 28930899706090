import {
  Pipe,
  PipeTransform
} from '@angular/core';

import { AssetUriResolverService } from './asset-uri-resolver.service';

@Pipe({
  name: 'resolveImageUri'
})
export class ResolveImageUriPipe implements PipeTransform {

  constructor(
    private $assetUriResolver: AssetUriResolverService) { }

  transform(...args: Parameters<AssetUriResolverService['resolveImageUri']>): ReturnType<AssetUriResolverService['resolveImageUri']> {
    return this.$assetUriResolver.resolveImageUri(...args);
  }

}
