import {
  Component,
  OnInit
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import {
  ImageCarouselItem,
  MiaActionPerformerService
} from 'src/mia-components/public-api';

@Component({
  selector: 'app-ambulance-dispatch',
  templateUrl: './ambulance-dispatch.page.html',
  styleUrls: ['./ambulance-dispatch.page.scss'],
})
export class AmbulanceDispatchPage implements OnInit {

  carouselItems: ImageCarouselItem[] = [
    {
      image: ['imgLogin', 'jpg'],
      text: 'ambulanceDispatch.imageText',
      strong: 'ambulanceDispatch.imageTextStrong'
    }
  ];

  constructor(
    private $modalCtrl: ModalController,
    private $miaActionPerformer: MiaActionPerformerService) { }

  ngOnInit() {
  }

  callAmbulance() {
    this.$miaActionPerformer.perform('tel');
    this.closeModal();
  }

  closeModal() {
    this.$modalCtrl.dismiss();
  }
}
