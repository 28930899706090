import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PersonalData, PersonalDataService } from '../personal-data.service';

@Component({
  selector: 'app-carton-digital',
  templateUrl: './carton-digital.page.html',
  styleUrls: ['./carton-digital.page.scss'],
})
export class CartonDigitalPage implements OnInit {

  userData: PersonalData;

  constructor(
    private $modalCtrl: ModalController,
    private $personalData: PersonalDataService
  ) { }

  async ngOnInit() {
    this.userData = await this.$personalData.getLastEntry();
  }

  closeModal() {
    this.$modalCtrl.dismiss();
  }

}
