import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ActionObject, DeepMenuItem } from 'src/mia-components/public-api';
import { PersonalDataService } from '../personal-data.service';

import ConfigBR from '../../assets/styles/br/config.json';
import ConfigFS from '../../assets/styles/fontes/config.json'; 
//import ConfigNFS from '../../assets/styles/nfs/config.json';

@Component({
  selector: 'app-assistances-menu',
  templateUrl: './assistances-menu.page.html',
  styleUrls: ['./assistances-menu.page.scss'],
})
export class AssistancesMenuPage implements OnInit {
  menuItems:any;

  constructor(private $modalCtrl: ModalController,
    private $personalData: PersonalDataService,
    private $translate: TranslateService,
  ) {
    let idClient = 'br';
    if (window.location.pathname.split('/').slice(1)) {
      var arrayParams = window.location.pathname.split('/').slice(1);
      if (arrayParams.length > 0) {
        idClient = arrayParams[0];

        if (idClient === 'br') {
          this.menuItems = ConfigBR.urgencias;
        
        } else if (idClient === 'fontes') {
          this.menuItems = ConfigFS.urgencias;

        } /*else if (idClient === 'nfs') {
          this.menuItems = ConfigNFS.urgencias;
        }*/
      }
    
    } else {
      //DEFAULT BR
      this.menuItems = ConfigBR.urgencias;
    }
  }

  async ngOnInit() {
    // this.$readConfic.configClient().subscribe((result: DeepMenuItem[]) => {
    //   let menuItems: DeepMenuItem[] = result;
    // });

    // const { id, ...personalData } = await this.$personalData.getLastEntry();

    // const getWame = (service: string): ActionObject => {
    //   let strPersonalData: string = `Nombre: ${personalData.firstName}
    //   Apellido paterno: ${personalData.fatherLastName}
    //   Apellido materno: ${personalData.motherLastName}
    //   Correo: ${personalData.email}
    //   Teléfono: ${personalData.phoneNumber}
    //   Certificado: ${personalData.policy}
    //   Servicio: ${this.$translate.instant(service).replace(/<[^>]+\/?>/g, '')}`

    // return {
    //   type: 'link',
    //   link: 'https://api.whatsapp.com/send?phone={phone}&text={text}',
    //   linkVars: {
    //     phone: '5511934414555',
    //     text: encodeURIComponent(strPersonalData),
    //     type: `${this.$translate.instant(service).replace(/<[^>]+\/?>/g, '')}`
    //   },
    //   tracked: 'whatsapp'
    // }
    //}

    // const providerNetworkItem = this.menuItems.find(menuItem => menuItem.title === 'assistancesMenu.providerNetworkBtn')
    // if (providerNetworkItem) {
    //   providerNetworkItem.action = getWame('assistancesMenu.providerNetworkBtn')
    // }

    // const submenusistancesMenu = this.menuItems.find(menuItem => menuItem.title === 'assistancesMenu.submenusistancesMenu')
    // if (submenusistancesMenu) {
    //   submenusistancesMenu.submenu.forEach(e => (e.action = getWame(e.title)));
    // }

  }

  closeModal() {
    this.$modalCtrl.dismiss();
  }
}
