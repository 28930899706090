import {
  NgxIndexedDBService,
  ObjectStoreMeta
} from 'ngx-indexed-db';
import { DBEmitter } from './db-emitter';

/**
 * Create an object store service ready to be annotated and injected
 */
export function createMiaObjectStoreService<I extends object>(
  storeName: string,
  props: Array<keyof I>
) {
  const storeConfig: ObjectStoreMeta = {
    store: storeName,
    storeConfig: {
      keyPath: 'id',
      autoIncrement: true
    },
    storeSchema: props.map(prop => ({
      name: prop as string,
      keypath: prop as string,
      options: { unique: false }
    }))
  };

  return class MiaObjectStoreServiceClass extends DBEmitter {
    static config = storeConfig;

    constructor(
      public $dbService: NgxIndexedDBService<I>) {
      super();
    }

    async addEntry(data: I): Promise<number> {
      const result = await this.$dbService
        .add(
          storeName,
          data
        )
        .toPromise();

      this.emitAdded();
      return result;
    }

    getEntries(): Promise<I[]> {
      return this.$dbService
        .getAll(storeName)
        .toPromise();
    }

    async getLastEntry(): Promise<I> {
      const data = await this.$dbService
        .getAll(storeName)
        .toPromise();

      return data[data.length - 1];
    }

    async hasEntries(): Promise<boolean> {
      const dataCount = await this.$dbService
        .count(storeName)
        .toPromise();

      return !!dataCount;
    }

    clearEntries(): Promise<boolean> {
      return this.$dbService
        .clear(storeName)
        .toPromise();
    }
  };
}
