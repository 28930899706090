import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ActionObject, ImageCarouselItem, MiaActionPerformerService } from 'src/mia-components/public-api';
import { PersonalData, PersonalDataService } from '../personal-data.service';

@Component({
  selector: 'app-telephone-medical-consultation',
  templateUrl: './telephone-medical-consultation.page.html',
  styleUrls: ['./telephone-medical-consultation.page.scss'],
})
export class TelephoneMedicalConsultationPage implements OnInit {
  userData: PersonalData;
  @Input()
  carouselItems: ImageCarouselItem[];

  @Input()
  pageReference: string; //= 'telephoneMedicalConsultation';

  @Input()
  action: ActionObject;

  callBtn: boolean;
  callModal: boolean;
  whatsappBtn: boolean;
  onlineBtn: boolean;
  idClient: string = 'br';

  constructor(
    private $modalCtrl: ModalController,
    private $miaActionPerformer: MiaActionPerformerService,
    private $personalData: PersonalDataService
    ) {
      if (window.location.pathname.split('/').slice(1)) {
        var arrayParams = window.location.pathname.split('/').slice(1);
        if (arrayParams.length > 0) {
          this.idClient = arrayParams[0];
        }
      }

    }

  ngOnInit() {
    this.callBtn = this.action["callBtn"];
    this.whatsappBtn = this.action["whatsappBtn"];
    this.callModal = this.action["callModal"];
    this.onlineBtn = this.action["onlineBtn"];
  }

  callAmbulance() {
    this.action.type = "link";
    this.$miaActionPerformer.perform(this.action);
    this.closeModal();
  }

  callService() {
    this.action.type = "modal";
    this.$miaActionPerformer.perform(this.action);
    this.closeModal();
  }

  async callWhatsapp() {
    this.userData = await this.$personalData.getLastEntry();
    let url       = "";

    if (this.pageReference === 'farmacias') {
      url = `https://api.whatsapp.com/send?phone=5511930312189&text=${this.userData.documento}%20${this.userData.firstName}%20${this.userData.fatherLastName}`;
    } else {
      url = `https://api.whatsapp.com/send?phone=551138716747&text=${this.userData.documento}%20${this.userData.firstName}%20${this.userData.fatherLastName}`;
    }

    openPopupFromAnchor(url);
  }

  callOnline() {
    this.action = {
      type: 'modal',
      modal: 'homeDoctor',
      props: { pageReference: this.pageReference }
    }

    this.$miaActionPerformer.perform(this.action);
    this.closeModal();
  }

  closeModal() {
    this.$modalCtrl.dismiss();
  }
}

function openPopupFromAnchor(href) {
  const anchor = document.createElement('a');
  anchor.href = href;
  anchor.target = '_href';
  anchor.click();
}
