import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import {
  DynamicFormField,
  DynamicFormFieldTypes,
  DynamicFormOption,
  MiaAlertService,
  MiaGeolocationService,
  MiaLoadingService,
  MiaLoggerService,
  MiaModalResolverService
} from 'src/mia-components/public-api';

import { PharmaClientService } from '../pharma.service';
import { Provider } from '../provider';

@Component({
  selector: 'app-pharma',
  templateUrl: './pharma.page.html',
  styleUrls: ['./pharma.page.scss'],
})
export class PharmaPage implements OnInit {
  static MAX_PROVIDERS_DISTANCE = 5000; // In meters
  form = new FormGroup({});

  manualGeolocationFields: DynamicFormField[] = [
    {
      type: DynamicFormFieldTypes.select,
      name: 'establishment',
      label: 'pharmaPage.establishmentInput',
      rules: {
        required: true
      },
      options: []
    },
    {
      type: DynamicFormFieldTypes.select,
      name: 'province',
      label: 'pharmaPage.provinceInput',
      rules: {
        required: true
      },
      options: []
    },
    {
      type: DynamicFormFieldTypes.select,
      name: 'locality',
      label: 'pharmaPage.localityInput',
      rules: {
        required: true
      },
      options: []
    }
  ];

  urlPharma: ''
  constructor(
    private $modalCtrl: ModalController,
    private $miaAlert: MiaAlertService,
    private $miaModalResolver: MiaModalResolverService,
    private $miaLoading: MiaLoadingService,
    private $miaLogger: MiaLoggerService,
    private $miaGeolocation: MiaGeolocationService,
    private $pharmaService: PharmaClientService) { }

  async ngOnInit() {
    try {
      const { access_token } = await this.$pharmaService.login();
      const result = await this.$pharmaService.getStates('41241', access_token);

      // Assign variable attributes
      this.manualGeolocationFields[0].options = result.estados.map(value => ({
        label: value.nome,
        value: value.codigo
      }));
    } catch (error) {
      console.log('error:');
      console.error(error);

      this.$miaAlert.openError(
        'homeDoctor.errorLoadingFieldsText'
      );
    }
  }
  
  async openGeolocationUsingSpecificLocation()
  {
    const stateValue = this.form.get('establishment').value;
    const cityValue = this.form.get('province').value;
    const barrioValue = this.form.get('locality').value;
    const { access_token } = await this.$pharmaService.login();

    const redes = await this.$pharmaService.getRede('41241', stateValue, cityValue, barrioValue,  access_token);
    //var farmacias: Provider[];

    if (!redes) {
      return this.$miaAlert.openError(
        'providerNetwork.noProvidersFound'
      );
    }

    const providers:Provider[] = await redes['farmacias'].map(value => ({
      'NOMBRE DE PROVEEDOR': value.loja,
      'DEPARTAMENTO': value.estado,
      'CIUDAD': value.cidade,
      'DIRECCION': value.endereco + '/ ' + value.complemento,
      'TIPO DE SERVICIO': 'Farmacias',
      /*'HORARIO': '',*/
      'DESCUENTO OTORGADO': '5% a 60%',
      'LATITUD': value.latitude!=null? value.latitude.replace(',','.') : 0,
      'LONGITUD': value.longitude!=null?value.longitude.replace(',','.'): 0,
      'NOMBRE DE ICONO': 'default.svg',
      'TELEFONO CENTRAL TELEFONICA': '55' + value.telefone,
      'CATEGORIA MENU': 'Farmacias'
    }));

    //console.log (providers);
    this.$miaModalResolver.open('providerNetworkMap', {
      componentProps: {
        providers
      }
    });
  }

  closeModal() {
    this.$modalCtrl.dismiss();
  }

  async onSelectChange(field: DynamicFormField) {

    const { access_token } = await this.$pharmaService.login();

    var result;
    if(field.name === 'establishment'){
      const fieldValue = this.form.get(field.name).value;
      result = await this.$pharmaService.getCities('41241', fieldValue, access_token)

      this.form.get('province').setValue('');
      const provinceField = this.manualGeolocationFields.find(({ name }) => name === 'province');

      provinceField.options = result.cidades.map(option => ({
        label: option.nome,
        value: option.nome
      }));
    }else if (field.name === 'province'){
      const stateValue = this.form.get('establishment').value;
      const cityValue = this.form.get(field.name).value;
      result = await this.$pharmaService.getDistricts('41241', stateValue, cityValue, access_token)

      const targetField = 'locality';
      this.form.get(targetField).setValue('');

      const localityField = this.manualGeolocationFields.find(({ name }) => name === targetField);

      localityField.options = result.bairros.map(option => ({
        label: option.nome,
        value: option.nome
      }));
    }
    else if (field.name === 'establishment'){
      console.log('despliegue mapa');
    }


    // const fromField = 'province';
    // if (field.name !== fromField) {
    //   return;
    // }

    // const fieldValue = this.form.get(field.name).value;
    // const targetField = 'locality';
    // this.form.get(targetField).setValue('');

    // const options = [];

    // const localityField = this.manualGeolocationFields.find(({ name }) => name === targetField);

    // localityField.options = options.map(option => ({
    //   label: option,
    //   value: option
    // }));



  }
}
