import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthClientService } from '../api/auth-client.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class assistanceService {
  subsidiaryConfig: any;

  constructor(
    private $http: HttpClient,
    private $auth2: AuthClientService,
  ) {}

  async createAssitance(data: any) {
    const assitances: assitance = this.convertInterface(data);
    const { access_token } = await this.$auth2.login();
    var headers2send = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${access_token}`,
      'Accept-Language': 'EN',
      'countryId': environment.countryId
    }
    return this.$http.post(`${environment.apiClientes.url_Assistance}/api/assistances`, assitances, {
      headers: headers2send
    }).toPromise();
  }

  //Método para guardado de documentos en una asistencia
  async saveDocumentAssintace(idAssistance: any, doc: AssistDoc) {
    const documentSave: document = this.setDocument(doc);
    const { access_token } = await this.$auth2.login();
    var headers2send = {
      'Authorization': `Bearer ${access_token}`,
      'Accept-Language': 'EN',
      'countryId': environment.countryId
    }
    return this.$http.post(`${environment.apiClientes.url_Assistance}/api/assistance/${idAssistance}/attachments`, documentSave, {
      headers: headers2send
    }).toPromise();
  }

  //Método que consulta los detalles de un servicio
  async getDetailService(clientId: any, assistanceId: any, serviceId: any) {
    const { access_token } = await this.$auth2.login();
    var headers2send = {
      'Authorization': `Bearer ${access_token}`,
      'Accept-Language': 'EN',
      'countryId': environment.countryId
    }
    return this.$http.post(`${environment.apiClientes.url}/api/client/${clientId}/assistance/${assistanceId}/service/${serviceId}`, {
      headers: headers2send
    }).toPromise();
  }

  //Método que consulta expedientes de un usuario
  async getExpedient(clientId) {
    const { access_token } = await this.$auth2.login();
    var headers2send = {
      'Authorization': `Bearer ${access_token}`,
      'Accept-Language': 'EN',
      'countryId': environment.countryId
    }
    return this.$http.post(`${environment.apiClientes.url}/api/client/${clientId}/assistances`, {
      headers: headers2send
    }).toPromise();
  }

  //Método que consulta historial de un cliente
  async getHistory(clientId: any, insuredId: any, idNum: any) {
    const { access_token } = await this.$auth2.login();
    var headers2send = {
      'Authorization': `Bearer ${access_token}`,
      'Accept-Language': 'EN',
      'countryId': environment.countryId
    }
    let data = {
      idNum: idNum,
      insuredId: insuredId
    };
    return this.$http.post(`${environment.apiClientes.url_Assistance}/api/client/${clientId}/assistances`, data, {
      headers: headers2send
    }).toPromise();
  }

  //Método que consulta asistencias de un cliente
  async getAsistances(clientId: any) {
    const { access_token } = await this.$auth2.login();
    var headers2send = {
      'Authorization': `Bearer ${access_token}`,
      'Accept-Language': 'EN',
      'countryId': environment.countryId
    }
    return this.$http.get(`${environment.apiClientes.url}/api/client/${clientId}/assistances`, {
      headers: headers2send
    }).toPromise();
  }

  //Método que consulta servicios de una asistencia
  async getServiceFromAssist (clientId: any, assistanceId: any) {
    const { access_token } = await this.$auth2.login();
    var headers2send = {
      'Authorization': `Bearer ${access_token}`,
      'Accept-Language': 'EN',
      'countryId': environment.countryId
    };
    return this.$http.get(`${environment.apiClientes.url}/api/client/${clientId}/assistance/${assistanceId}/services`, {
      headers: headers2send
    }).toPromise();
  }

  //Método que consulta información de una asistencia en concreto
  async getInfoAssist (clientId: any, assistanceId: any, serviceId: any) {
    const { access_token } = await this.$auth2.login();
    var headers2send = {
      'Authorization': `Bearer ${access_token}`,
      'Accept-Language': 'EN',
      'countryId': environment.countryId
    };
    return this.$http.get(`${environment.apiClientes.url}/api/client/${clientId}/assistance/${assistanceId}/service/${serviceId}`, {
      headers: headers2send
    }).toPromise();
  }

  async infadicional(clientId: any, contractId: any, supplementId: any, causeCd: any, riskCd: any, guaranteeCd: any, serviceCd: any) {
    const { access_token } = await this.$auth2.login();
    var headers2send = {
      'Authorization': `Bearer ${access_token}`,
      'Accept-Language': 'EN',
      'countryId': environment.countryId
    }
    let params = {
      attributeType: "variable",
      causeCd: causeCd,
      riskCd: riskCd,
      guaranteeCd: guaranteeCd,
      serviceCd: serviceCd
    };
    return this.$http.get(`${environment.apiClientes.url}/api/client/${clientId}/contract/${contractId}/supplement/${supplementId}/attributes`, {
      headers: headers2send,
      params: params
    }).toPromise();
  }

  private convertInterface(data: any): assitance {
    const valores: assitance = {
      client: {
        clientId: data.crearAsistencia.mseCrearAsistencia.cabecera.CLIENT,
        channel: "MaiAssist",
        foreignFile: data.crearAsistencia.mseCrearAsistencia.cabecera.FILE,
        msgClient: data.crearAsistencia.mseCrearAsistencia.atributosFijos.MSG_CLIENT
      },
      insured: {
        policy: data.crearAsistencia.mseCrearAsistencia.atributosFijos.POLICY,
        nif: data.crearAsistencia.mseCrearAsistencia.atributosFijos.DNI,
        name: data.crearAsistencia.mseCrearAsistencia.atributosFijos.NAME,
        surname1: data.crearAsistencia.mseCrearAsistencia.atributosFijos.SURNAME1,
        surname2: data.crearAsistencia.mseCrearAsistencia.atributosFijos.SURNAME2,
        phone: data.crearAsistencia.mseCrearAsistencia.atributosFijos.TELEPHONE,
        email: data.crearAsistencia.mseCrearAsistencia.atributosFijos.EMAIL,
        portfolio: {
          contractId: data.crearAsistencia.mseCrearAsistencia.atributosFijos.codclient,
          supplementId: data.crearAsistencia.mseCrearAsistencia.atributosFijos.supplementId
        }
      },
      declaration: {
        causeCd: data.crearAsistencia.mseCrearAsistencia.atributosFijos.CAUSE,
        breakdownCd: data.crearAsistencia.mseCrearAsistencia.atributosFijos.BREAKDOWN,
        riskCd: data.crearAsistencia.mseCrearAsistencia.atributosFijos.RISK,
        guaranteeCd: data.crearAsistencia.mseCrearAsistencia.atributosFijos.GUARANTEE,
        serviceCd: data.crearAsistencia.mseCrearAsistencia.atributosFijos.SERVICE,
        origin: {
          longitude: data.crearAsistencia.mseCrearAsistencia.atributosFijos.ORIGEN_LON,
          latitude: data.crearAsistencia.mseCrearAsistencia.atributosFijos.ORIGEN_LAT,
          place: data.crearAsistencia.mseCrearAsistencia.atributosFijos.LOCATION
        },
        destination: {
          longitude: data.crearAsistencia.mseCrearAsistencia.atributosFijos.DESTINO_LON,
          latitude: data.crearAsistencia.mseCrearAsistencia.atributosFijos.DESTINO_LAT,
          place: data.crearAsistencia.mseCrearAsistencia.atributosFijos.LOCATION
        },
        comment: data.crearAsistencia.mseCrearAsistencia.atributosFijos.COMMENTS
      },
      planification: {
        startDate: data.crearAsistencia.mseCrearAsistencia.atributosFijos.SERVICE_DATE,
        endDate: data.crearAsistencia.mseCrearAsistencia.atributosFijos.SERVICE_DATE
      },
      messenger: {
        type: "EMAIL",
        channel: data.crearAsistencia.mseCrearAsistencia.atributosFijos.EMAIL
      },
      attributes: data.crearAsistencia.mseCrearAsistencia.atributosVariables
    }
    return valores;
  }
  
  private setDocument(doc: AssistDoc): document {
    const document: document = {
      clientId: doc.TYPE,
      handler: "USER",
      document: {
        name: doc.FILE_NAME,
        content: doc.DOC,
        type: doc.TYPE,
        description: doc.ADDITIONAL_INF
      }
    };
    return document;
  }
}

interface assitance {
  client: {
    clientId: number;
    channel: string;
    handler?: string;
    msgClient?: string;
    foreignFile: string;
    assistanceId?: string;
  },
  insured: {
    insuredId?: number;
    policy?: string;
    nif: string;
    name: string;
    surname1: string;
    surname2: string;
    phone: string;
    email: string;
    portfolio: {
      contractId: string;
      supplementId: string;
    }
  },
  declaration: {
    occurrenceDate?: Date;
    causeCd: number;
    breakdownCd: number;
    riskCd: number;
    guaranteeCd: string;
    serviceCd: number;
    origin: {
      longitude: number;
      latitude: number;
      place: string;
    },
    destination: {
      longitude: number;
      latitude: number;
      place: string;
    };
    comment: string;
    interventionOrder?: number
  },
  planification: {
    startDate: Date;
    endDate?: Date;
  },
  messenger: {
    type: string;
    channel: string;
  },
  attributes: attribute[];
}

interface document {
  clientId: number;
  handler: string;
  document: {
    name: string;
    content: string;
    type: number;
    description: string;
  }
}

interface attribute {
  attributeCd: string;
  value: string
}

interface AssistDoc {
  TYPE?: number;
  ADDITIONAL_INF?: string;
  FILE_NAME?: string;
  DOC?: string;
}