import { defineMiaLang } from './shared';

export const es = defineMiaLang({
  historyModal: {
    title: 'Histórico',
    itemTextByType: {
      tel: 'Chamada feita',
      whatsapp: 'Contato WhatsApp',
      assistance: 'Assistência criada. No. #{{ id }}'
    }
  },
  contactModal: {
    title: 'Contate-Nos',
    itemTextByAction: {
      tel: 'Assistência por chamada',
      whatsapp: 'Assistência por Whatsapp'
    }
  },
  geolocationModal: {
    title: 'Médico em casa',
    propertyInputTitle: 'Complemento do Endereço',
    propertyInputPlaceholder: 'Exemplo : Andar, Torre',
    btnsTitle: 'Endereço esta correto?',
    cancelBtn: 'Não',
    okBtn: 'Aceitar',
    calculatingGPSText: 'Cálculo da posição GPS',
    calculatingGPSErrorText: 'Ocorreu um erro ao obter a sua localização'
  },
  geolocationOptionsPopover: {
    message: 'Indique o formulário de inserção de local de sua preferência',
    retryBtn: 'Nova tentativa de GPS',
    manualBtn: 'Manual'
  },
  bottomMenu: {
    historyBtn: 'Histórico'
  }
});
