import { Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';

import { HistoryEntry } from './history';
import { createMiaObjectStoreService } from './mia-object-store-service-creator';

@Injectable({
  providedIn: 'root'
})
export class MiaHistoryService extends createMiaObjectStoreService<HistoryEntry>(
  'history',
  [
    'type',
    'date',
    'data'
  ]
) {
  constructor(
    $dbService: NgxIndexedDBService) {
    super($dbService);
  }

  addHistoryEntry(type: HistoryEntry['type'], data: HistoryEntry['data']): Promise<number> {
    return super.addEntry({
      type,
      data,
      date: new Date()
    });
  }
}
/*
export class MiaHistoryService extends DBEmitter {

  static STORE_CONFIG: ObjectStoreMeta = {
    store: 'history',
    storeConfig: {
      keyPath: 'id',
      autoIncrement: true
    },
    storeSchema: [
      {
        name: 'title',
        keypath: 'title',
        options: { unique: false }
      },
      {
        name: 'date',
        keypath: 'date',
        options: { unique: false }
      },
      {
        name: 'type',
        keypath: 'type',
        options: { unique: false }
      }
    ] as HistoryEntrySchema[]
  };

  constructor(
    private $dbService: NgxIndexedDBService<HistoryEntry>) {
    super();
  }

  async addEntry(type: HistoryEntry['type'], data: HistoryEntry['data']): Promise<number> {
    const result = await this.$dbService.add(
      MiaHistoryService.STORE_CONFIG.store,
      {
        type,
        data,
        date: new Date()
      } as HistoryEntryWithoutID as any
    ).toPromise();

    this.emitAdded();
    return result;
  }

  getEntries(): Promise<HistoryEntry[]> {
    return this.$dbService.getAll(
      MiaHistoryService.STORE_CONFIG.store
    ).toPromise();
  }

  getEntriesCount(): Promise<number> {
    return this.$dbService.count(
      MiaHistoryService.STORE_CONFIG.store
    ).toPromise();
  }
}
*/
