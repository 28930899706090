import { Injectable } from '@angular/core';
import { createMiaObjectStoreService } from 'src/mia-components/public-api';
import { NgxIndexedDBService } from 'ngx-indexed-db';

export interface PersonalData {
  id?: number;
  documento: number;
  firstName: string;
  fatherLastName: string;
  email: string;
  phoneNumber: string;
}

@Injectable({
  providedIn: 'root'
})
export class PersonalDataService extends createMiaObjectStoreService<PersonalData>(
  'personalData',
  [
    'documento',
    'firstName',
    'fatherLastName',
    'email',
    'phoneNumber'
  ]
) {
  constructor($dbService: NgxIndexedDBService) {
    super($dbService);
  }
}
