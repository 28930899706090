import { Injectable } from '@angular/core';
import { ImageMenuItem } from '../mia-components/lib/image-menu';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  items: any = [];

  constructor() { }
}
