import { EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';

export type DBEmitterAllTypes =
  | 'added'
  | 'modified'
  | 'deleted';

export type DBEmitterListener = () => void;
export type DBEmitterAllListener = (type: DBEmitterAllTypes) => void;

export class DBEmitter {
  /**
   * Emitter for addition events
   */
  added = new EventEmitter();

  /**
   * Emitter for modifications events
   */
  modified = new EventEmitter();

  /**
   * Emitter for deletions events
   */
  deleted = new EventEmitter();

  on(listener: DBEmitterAllListener): () => void {
    const addedSub = this.added.subscribe(() => listener('added'));
    const modifiedSub = this.modified.subscribe(() => listener('modified'));
    const deletedSub = this.deleted.subscribe(() => listener('deleted'));

    return () => {
      addedSub.unsubscribe();
      modifiedSub.unsubscribe();
      deletedSub.unsubscribe();
    };
  }

  onAdded(listener: DBEmitterListener): Subscription {
    return this.added.subscribe(listener);
  }

  onModified(listener: DBEmitterListener): Subscription {
    return this.modified.subscribe(listener);
  }

  onDeleted(listener: DBEmitterListener): Subscription {
    return this.deleted.subscribe(listener);
  }

  emitAdded(): void {
    this.added.emit();
  }

  emitModified(): void {
    this.modified.emit();
  }

  emitDeleted(): void {
    this.deleted.emit();
  }
}
