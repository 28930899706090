import {
  Component,
  OnInit
} from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { BottomMenuItem, forceMiaTranslationsInstall } from 'src/mia-components/public-api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

  bottomMenuItems: BottomMenuItem[] = [
    // {
    //   text: 'bottomMenu.privacidadeBtn',
    //   icon: 'carpeta-new',
    //   action: {
    //     type: 'link',
    //     link: 'https://politica.mapfre.com.br/#/politica-privacidade'
    //   }
    // },
    {
      text: 'bottomMenu.privacidadeBtn',
      icon: 'carpeta-new',
      action: {
        type: 'modal',
        modal: 'TermsConditions'
      }
    },
    {
      text: 'bottomMenu.contactBtn',
      icon: 'call-outline',
      action: 'tel'
    }
  ];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private $translate: TranslateService) {
    this.initializeApp();
  }

  ngOnInit() {
    this.$translate.currentLoader
      .getTranslation('es')
      .subscribe(() => {
        // forceMiaTranslationsInstall(this.$translate);
        
        forceMiaTranslationsInstall({
          setTranslation: (...args: any[]) => {
          //delete args[1].mia.historyModal.itemTextByType.whatsapp;
          
          this.$translate.setTranslation(...(args as [any, any, any]));
          }
          } as any);

        this.$translate.use(this.$translate.getBrowserLang());
      });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }
}
