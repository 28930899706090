import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output
} from '@angular/core';

import { BottomMenuItem } from '../bottom-menu';
import { MiaActionPerformerService } from '../mia-action-performer.service';
import { MiaHistoryService } from '../mia-history.service';

@Component({
  selector: 'mia-bottom-menu',
  templateUrl: './mia-bottom-menu.component.html',
  styleUrls: ['./mia-bottom-menu.component.scss']
})
export class MiaBottomMenuComponent implements OnInit {

  @Input()
  showHistory = false;

  @Input()
  historyText = 'mia.bottomMenu.historyBtn';

  @Input()
  historyIcon = 'document-text-outline';

  @Input()
  items: BottomMenuItem[];

  @Output()
  selectMenuItem = new EventEmitter<BottomMenuItem>();

  showMenu = false;

  private historyItem: BottomMenuItem = {
    text: '',
    icon: '',
    disable: false,
    action: {
      type: 'modal',
      modal: 'history'
    }
  };

  get allItems(): BottomMenuItem[] {
    return [
      ...this.items
    ];
  }

  @HostBinding('class.hidden')
  get hiddenMenu(): boolean {
    return !this.showMenu;
  }

  constructor(
    private $miaActionPerformer: MiaActionPerformerService,
    private $miaHistory: MiaHistoryService) { }

  async ngOnInit(): Promise<void> {
    this.patchHistoryItem();

    const updateDisable = async () => {
      this.historyItem.disable = !await this.$miaHistory.hasEntries();
    };

    this.$miaHistory.on(updateDisable);
    await updateDisable();
  }

  toggleMenu(): void {
    this.showMenu = !this.showMenu;
  }

  emitSelectMenuItem(item: BottomMenuItem): void {
    this.selectMenuItem.emit(item);
  }

  onSelectMenuItem(item: BottomMenuItem): void {
    this.$miaActionPerformer.perform(item.action);
    this.emitSelectMenuItem(item);
    this.toggleMenu();
  }

  private patchHistoryItem(): void {
    Object.defineProperties(
      this.historyItem,
      {
        text: {
          get: () => this.historyText
        },
        icon: {
          get: () => this.historyIcon
        }
      }
    );
  }
}
