import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  FormControl,
  Validators
} from '@angular/forms';
import {
  IonInput,
  ModalController,
  PopoverController
} from '@ionic/angular';

import { GeolocationData } from '../geolocation';
import { MiaAlertService } from '../mia-alert.service';
import { MiaGeolocationOptionsPopoverComponent } from '../mia-geolocation-options-popover/mia-geolocation-options-popover.component';
import { MiaLoadingService } from '../mia-loading.service';

@Component({
  selector: 'mia-geolocation-modal',
  templateUrl: './mia-geolocation-modal.component.html',
  styleUrls: ['./mia-geolocation-modal.component.scss']
})
export class MiaGeolocationModalComponent implements OnInit, AfterViewInit {

  @Input()
  title = 'mia.geolocationModal.title';

  @Input()
  propertyInputTitle = 'mia.geolocationModal.propertyInputTitle';

  @Input()
  propertyInputPlaceholder = 'mia.geolocationModal.propertyInputPlaceholder';

  @Input()
  btnsTitle = 'mia.geolocationModal.btnsTitle';

  @Input()
  cancelBtn = 'mia.geolocationModal.cancelBtn';

  @Input()
  okBtn = 'mia.geolocationModal.okBtn';

  @Input()
  calculatingGPSText = 'mia.geolocationModal.calculatingGPSText';

  @Input()
  calculatingGPSErrorText = 'mia.geolocationModal.calculatingGPSErrorText';

  @ViewChild('map')
  map: ElementRef<HTMLDivElement>;

  @ViewChild('autocompleteInput', { read: ElementRef })
  autocompleteInput: ElementRef<HTMLIonInputElement>;

  @ViewChild('autocompleteInput')
  autocompleteInputComponent: IonInput;

  propertyDetailControl = new FormControl(null, [
    Validators.required
  ]);

  private mapInstance: google.maps.Map;
  private autocompleteInstance: google.maps.places.Autocomplete;
  private userMarker: google.maps.Marker;

  constructor(
    private $modalCtrl: ModalController,
    private $popoverCtrl: PopoverController,
    private $miaLoading: MiaLoadingService,
    private $miaAlert: MiaAlertService) { }

  ngOnInit(): void {
  }

  async ngAfterViewInit(): Promise<void> {
    this._loadMap();
    await this._loadAutocomplete();

    this._centerMapCurrentLocation();
  }

  async openGeolocationPopover(event: MouseEvent): Promise<void> {
    const popover = await this.$popoverCtrl.create({
      component: MiaGeolocationOptionsPopoverComponent,
      cssClass: 'mia-popover'
    });

    popover
      .onWillDismiss()
      .then(({ data }) => {
        if (data === 'retry-gps') {
          this._centerMapCurrentLocation();
        } else {
          this.autocompleteInputComponent.setFocus();
        }
      });

    popover.present();
  }

  onAutocompleteChange($event: CustomEvent<{ value: string }>): void {
    if ($event.detail.value === '') {
      this.autocompleteInstance.set('place', null);
    }
  }

  centerMapToAutocompletePlace(): void {
    const { geometry } = this.autocompleteInstance.getPlace() ?? {};

    if (geometry) {
      this._centerUserAndMapTo(geometry.location);
    }
  }

  async saveLocation(): Promise<void> {
    this.closeModal({
      location: await this._getCurrentPlace(),
      property: this.propertyDetailControl.value ?? ''
    });
  }

  closeModal(data?: GeolocationData): void {
    this.$modalCtrl.dismiss(data);
  }

  private _loadMap(): void {
    const center = {
      lat: 0,
      lng: 0
    };

    this.mapInstance = new google.maps.Map(this.map.nativeElement, {
      center,
      zoom: 15,
      fullscreenControl: false,
      mapTypeControl: false,
      zoomControl: false,
      streetViewControl: false
    });

    this.mapInstance.addListener('click', this._onMapClick.bind(this));
    this._initUserMarkerAtPosition(center);
  }

  private _onMapClick({ latLng }: google.maps.MouseEvent | google.maps.IconMouseEvent): void {
    this._centerUserAndMapTo(latLng);
  }

  private async _getCurrentPlace(): Promise<google.maps.GeocoderResult> {
    const geocoder = new google.maps.Geocoder();
    const geocoderRequest: google.maps.GeocoderRequest = {
      location: this.userMarker.getPosition()
    };

    return new Promise((resolve, reject) => {
      geocoder.geocode(geocoderRequest, ([result], status) => {
        if (status !== google.maps.GeocoderStatus.OK) {
          reject(status);
        } else {
          resolve(result);
        }
      });
    });
  }

  private async _loadAutocomplete(): Promise<void> {
    const input = await this.autocompleteInput.nativeElement.getInputElement();

    this.autocompleteInstance = new google.maps.places.Autocomplete(input);
  }

  private _centerUserAndMapTo(center: Parameters<google.maps.Map['setCenter']>[0]): void {
    this.mapInstance.panTo(center);
    this._initUserMarkerAtPosition(center);
  }

  private _initUserMarkerAtPosition(position: google.maps.MarkerOptions['position']): void {
    if (this.userMarker) {
      this.userMarker.setMap(null);
    }

    this.userMarker = new google.maps.Marker({
      position,
      animation: google.maps.Animation.DROP
    });

    this.userMarker.setMap(this.mapInstance);
  }

  private async _centerMapCurrentLocation(): Promise<void> {
    const loading = await this.$miaLoading
      .present(this.calculatingGPSText);

    navigator.geolocation.getCurrentPosition(
      ({ coords }) => {
        const location: google.maps.LatLngLiteral = {
          lat: coords.latitude,
          lng: coords.longitude
        };

        const circle = new google.maps.Circle({
          center: location,
          radius: coords.accuracy
        });

        this._centerUserAndMapTo(location);

        // Reset autocomplete
        this.autocompleteInput.nativeElement.value = '';
        this.autocompleteInstance.setBounds(circle.getBounds());

        loading.dismiss();
      },
      error => {
        loading.dismiss();
        console.error(error);

        this.$miaAlert.openError(
          this.calculatingGPSErrorText
        );
      }
    );
  }
}
