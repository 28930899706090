import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MiaLoggerService {

  constructor() { }

  log(...args: any[]): void {
    return this._logByMethod('log', ...args);
  }

  error(...args: any[]): void {
    return this._logByMethod('error', ...args);
  }

  private _logByMethod(method: 'log' | 'error', ...args: any[]): void {
    return console[method](...args);
  }
}
