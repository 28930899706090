import { EventEmitter, Inject, Injectable, Output } from '@angular/core';
import { Router } from '@angular/router';
import {
  AlertController,
  IonicSafeString
} from '@ionic/angular';
import { AlertOptions } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';

import { OpenAlertOptions } from './alert';
import { AssetUriResolverService } from './asset-uri-resolver.service';
import {
  Config,
  ConfigToken
} from './config-token';

@Injectable({
  providedIn: 'root'
})
export class MiaAlertService {
  @Output()
  back = new EventEmitter<void>();

  constructor(
    @Inject(ConfigToken)
    private $config: Config,
    private $alertCtrl: AlertController,
    private $translate: TranslateService,
    private $assetUriResolver: AssetUriResolverService,
    private $router: Router) { }

  async open(options: OpenAlertOptions): Promise<HTMLIonAlertElement> {
    const { icon, inverted, ...restOptions } = options;
    const alertOptions: AlertOptions = { ...restOptions };

    if (!Array.isArray(options.message)) {
      options.message = [options.message];
    }

    const translatedMessage = await Promise.all(
      options
        .message
        .map(async (msg: string) => (
          `<p>${await this.$translate.get(msg).toPromise()}<p>`
        ))
    );

    alertOptions.message = translatedMessage.join('');

    if (icon) {
      alertOptions.message = `
        <img class="custom-alert-icon" src="${this.$assetUriResolver.resolveIconUri(icon)}" />
        ${alertOptions.message}
      `;
    }

    // Set as safe string after any transformation
    alertOptions.message = new IonicSafeString(alertOptions.message);
    alertOptions.cssClass = ['mia-alert'];

    if (inverted) {
      alertOptions.cssClass.push('inverted');
    }

    const alert = await this.$alertCtrl.create(alertOptions);
    await alert.present();

    return alert;
  }

  openError(message: OpenAlertOptions['message']): Promise<HTMLIonAlertElement> {
    return this.open({
      icon: this.$config.alert.errorIcon,
      inverted: true,
      message,
      buttons: [
        {
          text: 'OK',
          role: 'cancel'
        }
      ]
    });
  }

  openAlert(message: OpenAlertOptions['message']): Promise<HTMLIonAlertElement> {
    return this.open({
      inverted: true,
      message,
      buttons: [{
        text: 'OK',
        handler: () => {
          this.goHome();
        }
      }]
    });
  }

  async goHome(): Promise<void> {
    const $ionModals = document.querySelectorAll('ion-modal');

    // Dismiss all modals
    // tslint:disable-next-line
    for (let i = 0; i < $ionModals.length; i++) {
      await $ionModals[i].dismiss(
        null,
        'gesture' /* Use gesture so animations can be skipped */
      );
    }

    this.emitBack();
  }

  emitBack(): void {
    this.back.emit();
  }
}
