import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';

import { ImageMenuItem } from '../image-menu';
import { MiaActionPerformerService } from '../mia-action-performer.service';
import { ModalService } from 'src/app/modal.service';

@Component({
  selector: 'mia-image-menu',
  templateUrl: './mia-image-menu.component.html',
  styleUrls: ['./mia-image-menu.component.scss']
})
export class MiaImageMenuComponent implements OnInit {

  @Input()
  items: ImageMenuItem[];

  @Output()
  menuSelect = new EventEmitter<ImageMenuItem>();

  constructor(
    private $miaActionPerformer: MiaActionPerformerService,
    private $modalService: ModalService) { }

  ngOnInit(): void {
  }

  emitMenuSelect(item: ImageMenuItem): void {
    this.menuSelect.emit(item);
  }

  onMenuSelect(item: ImageMenuItem): void {
    this.$miaActionPerformer.perform(item.action);
    this.emitMenuSelect(item);
    this.$modalService.items = item;
  }
}
