import {
  Pipe,
  PipeTransform
} from '@angular/core';
import { AssetUriResolverService } from './asset-uri-resolver.service';

@Pipe({
  name: 'resolveIconUri'
})
export class ResolveIconUriPipe implements PipeTransform {

  constructor(
    private $assetUriResolver: AssetUriResolverService) { }

  transform(...args: Parameters<AssetUriResolverService['resolveIconUri']>): ReturnType<AssetUriResolverService['resolveIconUri']> {
    return this.$assetUriResolver.resolveIconUri(...args);
  }
}
