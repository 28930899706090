import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: ':id',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'personal-data-form',
    loadChildren: () => import('./personal-data-form/personal-data-form.module').then( m => m.PersonalDataFormPageModule)
  },
  {
    path: 'assistances-menu',
    loadChildren: () => import('./assistances-menu/assistances-menu.module').then( m => m.AssistancesMenuPageModule)
  },
  {
    path: 'ambulance-dispatch',
    loadChildren: () => import('./ambulance-dispatch/ambulance-dispatch.module').then( m => m.AmbulanceDispatchPageModule)
  },
  {
    path: 'telephone-medical-consultation',
    loadChildren: () => import('./telephone-medical-consultation/telephone-medical-consultation.module').then( m => m.TelephoneMedicalConsultationPageModule)
  },
  {
    path: 'home-doctor',
    loadChildren: () => import('./home-doctor/home-doctor.module').then( m => m.HomeDoctorPageModule)
  },
  {
    path: 'provider-network',
    loadChildren: () => import('./provider-network/provider-network.module').then( m => m.ProviderNetworkPageModule)
  },
  {
    path: 'provider-network-map',
    loadChildren: () => import('./provider-network-map/provider-network-map.module').then( m => m.ProviderNetworkMapPageModule)
  },
  {
    path: 'saludpreventiva-menu',
    loadChildren: () => import('./saludpreventiva-menu/saludpreventiva-menu.module').then( m => m.SaludpreventivaMenuPageModule)
  }
  ,
  {
    path: 'vantagensEmSaude-menu',
    loadChildren: () => import('./vantagens-em-saude-menu/vantagens-em-saude-menu.module').then( m => m.VantagensEmSaudeMenuPageModule)
  },
  {
    path: 'pharma',
    loadChildren: () => import('./pharma/pharma.module').then( m => m.PharmaPageModule)
  },
  {
    path: 'meeting-doctors',
    loadChildren: () => import('./meeting-doctors/meeting-doctors.module').then( m => m.MeetingDoctorsPageModule)
  },
  {
    path: 'cartonDigital',
    loadChildren: () => import('./carton-digital/carton-digital.module').then( m => m.CartonDigitalPageModule)
  },
  {
    path: 'terms-conditions',
    loadChildren: () => import('./terms-conditions/terms-conditions.module').then( m => m.TermsConditionsPageModule)
  },
  {
    path: 'telemedicina',
    loadChildren: () => import('./telemedicina/telemedicina.module').then( m => m.TelemedicinaPageModule)
  },
  {
    path: 'rede-credenciada',
    loadChildren: () => import('./rede-credenciada/rede-credenciada.module').then( m => m.RedeCredenciadaPageModule)
  }


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
