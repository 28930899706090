import { defineMiaLang } from './shared';

export const en = defineMiaLang({
  historyModal: {
    title: 'History',
    itemTextByType: {
      tel: 'Phone call',
      whatsapp: 'WhatsApp contact',
      assistance: 'Assistance created #{{ id }}'
    }
  },
  contactModal: {
    title: 'Contact us',
    itemTextByAction: {
      tel: 'Call assistance',
      whatsapp: 'Whatsapp assistance'
    }
  },
  geolocationModal: {
    title: 'Home doctor',
    propertyInputTitle: 'Property data',
    propertyInputPlaceholder: 'Apartment / Floor',
    btnsTitle: 'Is the location correcct?',
    cancelBtn: 'No',
    okBtn: 'Accept',
    calculatingGPSText: 'Calculating GPS\'s position',
    calculatingGPSErrorText: 'Error while getting your location'
  },
  geolocationOptionsPopover: {
    message: 'Indicate the way of entering the location you prefer',
    retryBtn: 'Retry GPS',
    manualBtn: 'Manual'
  },
  bottomMenu: {
    historyBtn: 'History'
  }
});
