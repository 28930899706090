import {
  Component,
  Input,
  OnInit
} from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'mia-geolocation-options-popover',
  templateUrl: './mia-geolocation-options-popover.component.html',
  styleUrls: ['./mia-geolocation-options-popover.component.scss']
})
export class MiaGeolocationOptionsPopoverComponent implements OnInit {

  @Input()
  icon = 'gps';

  @Input()
  message = 'mia.geolocationOptionsPopover.message';

  @Input()
  retryBtn = 'mia.geolocationOptionsPopover.retryBtn';

  @Input()
  manualBtn = 'mia.geolocationOptionsPopover.manualBtn';

  constructor(
    private $popoverCtrl: PopoverController) { }

  ngOnInit(): void {
  }

  closeModal(closeType?: string): void {
    this.$popoverCtrl.dismiss(closeType);
  }
}
