import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

interface TokenResult {
  access_token: string;
  token_type: string;
  expires_in: number;
}

interface insureds_info {
  contractId: string;
  supplementId: number;
  contract: string;
  insuredId: number;
  lastName: string;
  policy: string;
  idNum: string;
  startDate: string;
  address: string;
  productCd: string;
  product: string;
}

interface insureds {
  content: insureds_info[];
  totalPages: number;
  totalElements: number;
}


@Injectable({
  providedIn: 'root'
})

export class AuthClientService {
  idClient: any;
  subsidiaryConfig: any;
  credentials = {
    url: environment.apiClientes.url,
    url_auth: environment.apiClientes.url_auth
  };

  constructor(
    private $http: HttpClient,
  ) {}

  async getInsurance(idcliente: string, idnum: string) {
    const { access_token } = await this.login();
    return this.getInsuranceDS(idcliente, idnum, access_token);
  }

  login() {
    let headers2send = new HttpHeaders();
    headers2send = headers2send.append('Content-Type', 'application/x-www-form-urlencoded');

    if (window.location.pathname.split('/').slice(1)) {
      var arrayParams = window.location.pathname.split('/').slice(1);
      if (arrayParams.length > 0) {
        this.idClient = arrayParams[0];
      }

    } else {
      this.idClient = localStorage.getItem('idClient');
    }

    let clientId = environment[this.idClient]["client_id"];
    let clientSecret = environment[this.idClient]["client_secret"];

    const form2send = new HttpParams()
      .set('grant_type', 'client_credentials')
      .set('client_id', clientId)
      .set('client_secret', clientSecret);
    return this.$http.post<TokenResult>(this._getURLogin(), form2send, { headers: headers2send }).toPromise();
  }

  private getInsuranceDS(idcliente: string, idnum: string, token: string) {
    var headers2send = {
      'Authorization': `Bearer ${token}`,
      'Accept-Language': 'EN',
      'countryId': this.subsidiaryConfig.code
    }
    return this.$http
      .get<insureds>(
        this._getURL('api/client/' + idcliente + '/insureds?idNum=' + idnum),
        {
          headers: headers2send
        }
      );
  }

  private _getURL(path: string) {
    return `${this.credentials.url}/${path}`;
  }

  private _getURLogin(path?: string) {
    return `${this.credentials.url_auth}`;
  }
}