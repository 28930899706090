import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class MiaLoadingService {

  constructor(
    private $loadingCtrl: LoadingController,
    private $translate: TranslateService) { }

  async present(message: string): Promise<HTMLIonLoadingElement> {
    const translatedMessage = await this.$translate
      .get(message)
      .toPromise();

    const loading = await this.$loadingCtrl.create({
      message: `
        <div class="inner-loading-spinner"></div>
        ${translatedMessage}
      `,
      cssClass: ['mia-loading']
    });

    await loading.present();
    return loading;
  }
}
