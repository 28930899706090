import { CommonModule } from '@angular/common';
import {
  NgModule,
  ModuleWithProviders
} from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService
} from '@ngx-translate/core';
import {
  CONFIG_TOKEN,
  DBConfig,
  NgxIndexedDBService,
  ObjectStoreMeta
} from 'ngx-indexed-db';
import {
  Observable,
  of
} from 'rxjs';

import {
  ConfigToken,
  Config
} from './config-token';
import * as defaultTranslations from '../default-translations';

/**
 * Components
 */
import { MiaHeaderComponent } from './mia-header/mia-header.component';
import { MiaImageMenuComponent } from './mia-image-menu/mia-image-menu.component';
import { MiaPageTitleComponent } from './mia-page-title/mia-page-title.component';
import { MiaBottomMenuComponent } from './mia-bottom-menu/mia-bottom-menu.component';
import { MiaHistoryModalComponent } from './mia-history-modal/mia-history-modal.component';
import { MiaContactModalComponent } from './mia-contact-modal/mia-contact-modal.component';
import { MiaPreloadScreenComponent } from './mia-preload-screen/mia-preload-screen.component';
import { MiaAddToHomeScreenComponent } from './mia-add-to-home-screen/mia-add-to-home-screen.component';
import { MiaImageCarouselComponent } from './mia-image-carousel/mia-image-carousel.component';
import { MiaDeepMenuComponent } from './mia-deep-menu/mia-deep-menu.component';
import { MiaDynamicFormComponent } from './mia-dynamic-form/mia-dynamic-form.component';
import { MiaSelectItemComponent } from './mia-select-item/mia-select-item.component';
import { MiaContentComponent } from './mia-content/mia-content.component';
import { MiaButtonComponent } from './mia-button/mia-button.component';
import { MiaGeolocationModalComponent } from './mia-geolocation-modal/mia-geolocation-modal.component';
import { MiaGeolocationOptionsPopoverComponent } from './mia-geolocation-options-popover/mia-geolocation-options-popover.component';
import { MiaTacModalComponent } from './mia-tac-modal/mia-tac-modal.component';

/**
 * Pipes
 */
import { ResolveIconUriPipe } from './resolve-icon-uri.pipe';
import { ResolveImageUriPipe } from './resolve-image-uri.pipe';

/**
 * Services
 */
import { MiaHistoryService } from './mia-history.service';

// Export translations
export { defaultTranslations };

export class MiaDefaultTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return of(defaultTranslations[lang] ?? {});
  }
}

export function forceMiaTranslationsInstall(translateService: TranslateService): void {
  for (const lang of Object.keys(defaultTranslations)) {
    translateService.setTranslation(
      lang,
      defaultTranslations[lang],
      true
    );
  }
}

const declarations = [
  // Components
  MiaHeaderComponent,
  MiaImageMenuComponent,
  MiaPageTitleComponent,
  MiaBottomMenuComponent,
  MiaHistoryModalComponent,
  MiaContactModalComponent,
  MiaPreloadScreenComponent,
  MiaAddToHomeScreenComponent,
  MiaImageCarouselComponent,
  MiaDeepMenuComponent,
  MiaDynamicFormComponent,
  MiaSelectItemComponent,
  MiaContentComponent,
  MiaButtonComponent,
  MiaGeolocationModalComponent,
  MiaGeolocationOptionsPopoverComponent,
  MiaTacModalComponent,

  // Pipes
  ResolveIconUriPipe,
  ResolveImageUriPipe
];

const defaultModals: Config['modals'] = {
  history: MiaHistoryModalComponent,
  contact: MiaContactModalComponent,
  geolocation: MiaGeolocationModalComponent,
  termsAndConditions: MiaTacModalComponent
};

const defaultPopovers: Config['popovers'] = {
  geolocationOptions: MiaGeolocationOptionsPopoverComponent
};

const defaultActions: Config['actions'] = {
  whatsapp: {
    type: 'link',
    link: 'https://api.whatsapp.com/send?phone={phone}&text={text}',
    linkVars: {
      phone: '5511934414555',
      text: 'Olá, necessito uma assistência'
    },
    tracked: 'whatsapp'
  },
  tel: {
    type: 'link',
    link: 'tel:{phone}',
    linkVars: {
      phone: '08007027030'
    },
    tracked: 'tel'
  }
};

const defaultObjectStoresMeta: ObjectStoreMeta[] = [
  MiaHistoryService.config
];

@NgModule({
  declarations,
  imports: [
    IonicModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useClass: MiaDefaultTranslateLoader
      },
      extend: true,
      isolate: false
    })
  ],
  exports: [
    IonicModule,
    TranslateModule,
    ...declarations
  ]
})
export class MiaComponentsModule {
  static forRoot(config: Partial<Config>): ModuleWithProviders<MiaComponentsModule> {
    return {
      ngModule: MiaComponentsModule,
      providers: [
        NgxIndexedDBService,
        {
          provide: ConfigToken,
          useValue: {
            ...config,
            modals: {
              ...defaultModals,
              ...config.modals
            },
            actions: {
              ...defaultActions,
              ...config.actions
            },
            popovers: {
              ...defaultPopovers,
              ...config.popovers
            },
            alert: {
              ...config.alert,
              errorIcon: config.alert?.errorIcon ?? 'comprobado'
            }
          } as Config
        },
        {
          provide: CONFIG_TOKEN,
          useValue: {
            ...config.db,
            objectStoresMeta: [
              ...defaultObjectStoresMeta,
              ...config.db.objectStoresMeta
            ]
          } as DBConfig
        }
      ]
    };
  }
}
