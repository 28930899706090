import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';

import {
  DynamicFormField, DynamicFormFieldTypes, DynamicFormRules
} from '../dynamic-form';
import { MiaModalResolverService } from '../mia-modal-resolver.service';

import { MiaActionPerformerService } from '../mia-action-performer.service';

const customValidators: Record<string, ValidatorFn> = {
  // FIXME: Add amount validator from Mia Home
};

@Component({
  selector: 'mia-dynamic-form',
  templateUrl: './mia-dynamic-form.component.html',
  styleUrls: ['./mia-dynamic-form.component.scss']
})
export class MiaDynamicFormComponent implements OnInit, OnChanges {

  @Input()
  title: string;

  @Input()
  fields: DynamicFormField[];

  @Input()
  saveText: string;

  @Input()
  termsAndConditionsText: string;

  @Input()
  termsAndConditionsModalText: string;
  
  @Input()
  termsAndConditionsTextModalTitle: string

  @Input()
  termsAndConditionsLink: string;

  @Input()
  termsAndConditionsModalContent: string[];

  @Input()
  termsAndConditions = false;

  @Input()
  form: FormGroup = new FormGroup({});

  @Output()
  selectChange = new EventEmitter<DynamicFormField>();

  @Output()
  saveData = new EventEmitter();

  action: any;

  termsAccepted = false;

  get isValidForm(): boolean {
    return (
      this.form.valid
    );
  }

  constructor(
    private $miaModalResolver: MiaModalResolverService,
    private $miaActionPerformer: MiaActionPerformerService) { }

  ngOnInit(): void {
    this._buildFormGroup();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fields && !changes.fields.isFirstChange()) {
      this._buildFormGroup();
    }
  }

  showHelp(field: DynamicFormField): void {
    // TODO
  }

  emitSelectChange(field: DynamicFormField): void {
    this.selectChange.emit(field);
  }

  toggleTerms(): void {
    this.termsAccepted = !this.termsAccepted;
  }

  // openTermsAndConditions(event: Event): void {
  //   event.stopPropagation();

    
  // }
  
  openTermsAndConditions(event: Event): void {
    this.action = {
      type: 'modal',
      modal: 'TermsConditions'
    }
    this.$miaActionPerformer.perform(this.action);
    
    // this.$miaModalResolver.open('termsAndConditions', {
    //   componentProps: {
    //     title: this.termsAndConditionsTextModalTitle,
    //     content: this.termsAndConditionsModalContent
    //   }
    // });
    
    // window.open("https://www.mapfre.com.br/politica-privacidade/", "_blank");
  }

  saveForm(): void {
    this.saveData.emit();
  }

  isAmount(field: DynamicFormField): boolean {
    return field.type === DynamicFormFieldTypes.amount;
  }

  setAmountValue(field: DynamicFormField, event: CustomEvent<InputEvent>, mode: 'prefix' | 'suffix'): void {
    const control = this.form.get(field.name);
    const n = (control.value as string).split('.');

    n[mode === 'prefix' ? 0 : 1] = parseInt((event.detail.target as HTMLIonInputElement).value as any || '0', 10).toString();

    control.markAsTouched();
    control.setValue(n.join('.'));
  }

  getAmountValue(field: DynamicFormField, mode: 'prefix' | 'suffix'): string {
    return this.form.get(field.name).value.split('.')[mode === 'prefix' ? 0 : 1];
  }

  private _buildFormGroup(): void {
    for (const field of this.fields) {
      const validators = [];

      if (field.rules) {
        const rules: (keyof DynamicFormRules)[] = Object.keys(field.rules) as any;

        for (const rule of rules) {
          const ruleValue = field.rules[rule];
          const validator = Validators[rule] ?? customValidators[rule];

          if (typeof ruleValue === 'boolean' && ruleValue) {
            validators.push(validator);
          } else {
            validators.push(validator(ruleValue as any));
          }


        }
      }

      this.form.addControl(
        field.name,
        new FormControl(
          this.isAmount(field) ? '0.0' : null,
          validators
        )
      );
    }
  }
}
