import {
  Component,
  Input,
  OnInit
} from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'mia-tac-modal',
  templateUrl: './mia-tac-modal.component.html',
  styleUrls: ['./mia-tac-modal.component.scss']
})
export class MiaTacModalComponent implements OnInit {

  @Input()
  title: string;

  @Input()
  content: string[];

  constructor(
    private $modalCtrl: ModalController) { }

  ngOnInit(): void {
  }

  closeModal(): void {
    this.$modalCtrl.dismiss();
  }
}
