import {
  AfterViewInit,
  Component,
  Input,
  OnInit
} from '@angular/core';

@Component({
  selector: 'mia-preload-screen',
  templateUrl: './mia-preload-screen.component.html',
  styleUrls: ['./mia-preload-screen.component.scss']
})
export class MiaPreloadScreenComponent implements OnInit, AfterViewInit {

  @Input()
  miaLogo: string;

  @Input()
  mapfreLogo = 'mapfre-logo';

  @Input()
  autoHide = true;

  @Input()
  duration = 1500;

  hidden = false;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if (!this.autoHide) {
      return;
    }

    setTimeout(() => {
      this.hidden = true;
    }, this.duration);
  }
}
