import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

export interface Authentication {
  token:             string;
  user_email:        string;
  user_nicename:     string;
  user_display_name: string;
}

export interface dataCadastro {
  cpf:              string;
  nome:             string;
  sobrenome:        string;
  email:            string;
  celular:          string;
  cep:              string;
  rua:              string;
  numero:           string;
  bairro:           string;
  cidade:           string;
  estado:           string;
  sexo:             string;
  data_nascimento:  string;
  senha:            string;
  complemento?:     string;
}

export interface Cadastro {
  id:                                  string;
  username:                            string;
  name:                                string;
  surname:                             string;
  email:                               string;
  status:                              string;
  categories:                          string[];
  page_id:                             string;
  parceiro:                            string;
  celular:                             string;
  cep:                                 string;
  rua:                                 string;
  numero_endereco:                     string;
  complemento:                         string;
  bairro_endereco:                     string;
  cidade:                              string;
  estado:                              string;
  sexo:                                string;
  datanasc:                            string;
  google_userid:                       string;
  token_user:                          string;
  teleinterconsulta_cadastro:          boolean;
  teleinterconsulta_senha_configurada: boolean;
  whatsapp:                            boolean;
  external_id:                         boolean;
  email_ofuscado:                      string;
}

export interface dataAtendimiento {
  codigo_interno: string;
  dependente_id:  number;
  peso:           number;
  altura:         number;
  sintoma:        string;
  origem:         string;
  triagem:        string;
}

export interface Atendimiento {
  id:               number;
  valor_consulta:   string;
  voucher:          string;
  url_teleconsulta: string;
}

@Injectable({
  providedIn: 'root'
})
export class TelemedicinaService {
  url     : string = 'https://dev.conectamidia.com.br/wp-json/jwt-auth/v1';
  username: string = 'informarsaudedev@conectamedico.com.br';
  password: string = '1245fb28-e2ee-44dc-856d-59d8cab88c49';

  constructor(
    private $http: HttpClient
  ) {}

  authentication() {
    const body = {
      username: this.username,
      password: this.password
    };

    return this.$http.post<Authentication>(this.url+'/token', body);
  }

  cadastroPaciente(data:dataCadastro, token: string) {
    const headers = {
      Authorization: `Bearer ${token}`
    };

    const body = {
      cpf:              data.cpf,
      nome:             data.nome,
      sobrenome:        data.sobrenome,
      email:            data.email,
      celular:          data.celular,
      cep:              data.cep,
      rua:              data.rua,
      numero:           data.numero,
      bairro:           data.bairro,
      cidade:           data.cidade,
      estado:           data.estado,
      sexo:             data.sexo,
      data_nascimento:  data.data_nascimento,
      senha:            data.senha,
      complemento:      data.complemento
    };

    return this.$http.post<Cadastro>(this.url+'/pacientes', body, { headers: headers });
  }

  atendimientoPaciente(data: dataAtendimiento, token: string, tokenUser: string) {
    const headers = {
      'Tokenconecta': `${tokenUser}`,
      'Authorization': `Bearer ${token}`
    };

    const body = {
      codigo_interno: "nul",
      dependente_id: -41954324,
      peso: 68912205.02116933,
      altura: -4870554.895668715,
      sintoma: "Ut dolor exercitation in",
      origem: "reprehenderit",
      triagem: "sed esse amet velit Duis"
    };

    return this.$http.post<Atendimiento>(this.url+'/prontoatendimento', body, { headers: headers });
  }
}
