import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { assistanceService } from '../app/api/assistance.service'

interface TokenResult {
  token: string;
}

interface CreateAssistDataRoot {
  crearAsistencia: {
    mseCrearAsistencia: CreateAssistData;
  };
}

interface AssistDoc {
  TYPE?: number;
  ADDITIONAL_INF?: string;
  FILE_NAME?: string;
  DOC?: string;
}

export interface CreateAssistData {
  cabecera: {
    AMA: string;
    CLIENT: string;
    FILE: string;
    ID: string;
  };
  atributosFijos: {
    // En formato DDMMYYYY HHmm
    DATE: string;
    NAME: string;
    SURNAME1: string;
    SURNAME2?: string;
    POLICY?: string;
    RISK: number;
    GUARANTEE: string;
    CAUSE: number;
    SERVICE: number;
    BREAKDOWN?: string;
    COUNTRY1: string;
    ZONE1?: string;
    STREET?: string;
    LOCATION?: string;
    COUNTRY2?: string;
    ZONE2?: string;
    TELEPHONE?: string;
    DNI?: number;
    AMOUNT?: string;
    SERVICE_DATE?: string;
    MSG_CLIENT: string;
    REIMBURSEMENT?: string;
    COMMENTS?: string;
    EMAIL?: string;
    IBAN?: string;
    BANK?: string;
    OFFICE?: string;
    DC?: string;
    ACCOUNT?: string;
    SWIFT?: string;
    TPERSON?: string;
    CPERSON?: string;
    PAY_ROUTE?: string;
    PAY_METHOD?: string;
    CURRENCY?: string;
    CHANNEL?: string;
    ORIGEN_LAT?: number;
    ORIGEN_LON?: number;
    DESTINO_LAT?: number;
    DESTINO_LON?: number;
    supplementId?: number;
  };
  atributosVariables: Array<{
    attributeCd: any;
    value: any;
  }>;
  documentos?: AssistDoc[];
}

interface CreateAssistResult {
  crearAsistenciaResponse: {
    mssCrearAsistencia: {
      MSG_CLIENT: any;
      RESULT: 'OK' | string;
      AMA_ASSIST?: number; // Número de asistencia
      AMA_SERVICE?: number;
      AMA_EO?: number;
      AMA_DISPATCH?: string;
      AMA_ERROR?: {
        ERR_TEXT: string[] | string;
      }
    };
  };
}

@Injectable({
  providedIn: 'root'
})
export class MaiCreatorClientService {

  credentials = {
    url: 'https://api.mapfre.com/srv/maicreator',
    user: 'MAICLIENT',
    pass: 'M$1client'
  };

  constructor(
    private $http: HttpClient,
    private $assistanceService: assistanceService
  ) { }

  login() {
    const authorizationPair = btoa(`${this.credentials.user}:${this.credentials.pass}`);
    const headers = {
      Authorization: `Basic ${authorizationPair}`
    };

    return this.$http
      .post<TokenResult>(this._getURL('login'), {}, { headers })
      .toPromise();
  }

  createAssist(data: CreateAssistData, token: string) {
    const headers = {
      Authorization: `Bearer ${token}`
    };

    return this.$http
      .post<CreateAssistResult>(
        this._getURL('crearAsistencia'),
        { crearAsistencia: { mseCrearAsistencia: data } } as CreateAssistDataRoot,
        { headers }
      )
      .toPromise();
  }

  async createAsis(data) {
    let objAssist: CreateAssistResult = {
      crearAsistenciaResponse: {
        mssCrearAsistencia: {
          MSG_CLIENT: "",
          RESULT: "",
          AMA_ASSIST: 0,
          AMA_ERROR: {
            ERR_TEXT: ""
          }
        }
      }
    };

    await this.$assistanceService.createAssitance(data).then(
      (assits: ResponseAssistance) => {
        let idAssist = assits.assistanceId;
        objAssist.crearAsistenciaResponse.mssCrearAsistencia.AMA_ASSIST = idAssist;
        objAssist.crearAsistenciaResponse.mssCrearAsistencia.RESULT = 'OK';
        let arrayDocs = data['crearAsistencia']['mseCrearAsistencia']['documentos'];
        if (arrayDocs) {
          for (let doc of arrayDocs) {
            this.$assistanceService.saveDocumentAssintace(idAssist, doc).then();
          }
        }
      }, (error) => {
        objAssist.crearAsistenciaResponse.mssCrearAsistencia.RESULT = 'ERROR';
        objAssist.crearAsistenciaResponse.mssCrearAsistencia.AMA_ERROR['ERR_TEXT'] = error;
      }
    );
    
    return Promise.resolve(objAssist);
  }

  private _getURL(path: string) {
    return `${this.credentials.url}/${path}`;
  }
}

interface ResponseAssistance {
  assistanceId: number;
}