import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mia-page-title',
  templateUrl: './mia-page-title.component.html',
  styleUrls: ['./mia-page-title.component.scss']
})
export class MiaPageTitleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
