import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileConverterService {

  static CLEAN_BASE64_REGEX = /^data:image\/[^;]+;base64,/;

  constructor() { }

  toBase64(file: Blob): Promise<string> {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    return new Promise((resolve, reject) => {
      reader.addEventListener('error', reject);

      reader.addEventListener('load', () => {
        resolve((reader.result as string).replace(FileConverterService.CLEAN_BASE64_REGEX, ''));
      });
    });
  }
}
