import {
  Component,
  HostBinding,
  HostListener,
  Input,
  NgZone,
  OnInit
} from '@angular/core';

/**
 * NOTE: Taken from https://stackoverflow.com/a/51847335/11992125
 *
 * The BeforeInstallPromptEvent is fired at the Window.onbeforeinstallprompt handler
 * before a user is prompted to "install" a web site to a home screen on mobile.
 *
 * -deprecated Only supported on Chrome and Android Webview.
 */
interface BeforeInstallPromptEvent extends Event {
  /**
   * Returns an array of DOMString items containing the platforms on which the event was dispatched.
   * This is provided for user agents that want to present a choice of versions to the user such as,
   * for example, "web" or "play" which would allow the user to chose between a web version or
   * an Android version.
   */
  readonly platforms: Array<string>;

  /**
   * Returns a Promise that resolves to a DOMString containing either "accepted" or "dismissed".
   */
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed',
    platform: string
  }>;

  /**
   * Allows a developer to show the install prompt at a time of their own choosing.
   * This method returns a Promise.
   */
  prompt(): Promise<void>;
}

declare global {
  interface WindowEventMap {
    beforeinstallprompt: BeforeInstallPromptEvent;
  }
}

let componentInstance: MiaAddToHomeScreenComponent;
let promptEvent: BeforeInstallPromptEvent | undefined;
let denyUserPromotion = localStorage.getItem('deny-user-promotion');

// Hacky CODE to wait for component instance
const waitUntilInstance = (fn: () => void) => {
  if (componentInstance) {
    fn();
  } else {
    const intervalID = setInterval(() => {
      if (!componentInstance) {
        return;
      }

      fn();
      clearInterval(intervalID);
    }, 25);
  }
};

window.addEventListener('beforeinstallprompt', e => {
  if (denyUserPromotion) {
    return;
  }

  e.preventDefault();

  promptEvent = e;
  waitUntilInstance(() => componentInstance.setHidden(false));
});

window.addEventListener('appinstalled', () => {
  waitUntilInstance(() => componentInstance.setHidden(true));
});

@Component({
  selector: 'mia-add-to-home-screen',
  templateUrl: './mia-add-to-home-screen.component.html',
  styleUrls: ['./mia-add-to-home-screen.component.scss']
})
export class MiaAddToHomeScreenComponent implements OnInit {

  @Input()
  logo: string;

  @HostBinding('hidden')
  hidden = true;

  private get promptEvent(): BeforeInstallPromptEvent {
    return promptEvent;
  }

  constructor(
    private $zone: NgZone) { }

  ngOnInit(): void {
    componentInstance = this;
  }

  @HostListener('click')
  async addToHomeScreen(): Promise<void> {
    if (!this.promptEvent) {
      return this._close();
    }

    await this.promptEvent.prompt();

    const { outcome } = await this.promptEvent.userChoice;

    if (outcome === 'accepted') {
      //
    } else {
      //
    }

    this._close();
  }

  setHidden(value: boolean): void {
    this.$zone.run(() => {
      this.hidden = value;
    });
  }

  close(event: Event): void {
    event.stopPropagation();

    localStorage.setItem('deny-user-promotion', denyUserPromotion = (1).toString());
    this._close();
  }

  private _close(): void {
    this.hidden = true;
  }
}
