import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import {
  ActionObject,
  DynamicFormField,
  DynamicFormFieldTypes,
  DynamicFormOption,
  MiaAlertService,
  MiaGeolocationService,
  MiaLoadingService,
  MiaLoggerService,
  MiaModalResolverService,
  MiaProvidersLoaderService
} from 'src/mia-components/public-api';

import { Provider } from '../provider';

@Component({
  selector: 'app-provider-network',
  templateUrl: './provider-network.page.html',
  styleUrls: ['./provider-network.page.scss'],
})
export class ProviderNetworkPage implements OnInit {
  static MAX_PROVIDERS_DISTANCE = 5000; // In meters
  @Input()
  filter: string;

  @Input() 
  action: ActionObject;
  
  form = new FormGroup({});

  manualGeolocationFieldsProviders: Record<DynamicFormField['name'], keyof Provider> = {
    __proto__: null,
    establishment: 'TIPO DE SERVICIO',
    province: 'DEPARTAMENTO',
    locality: 'CIUDAD',
    category: 'CATEGORIA MENU'
  };

  manualGeolocationFields: DynamicFormField[] = [
    {
      type: DynamicFormFieldTypes.select,
      name: 'establishment',
      label: 'providerNetwork.establishmentInput',
      rules: {
        required: true
      },
      options: []
    },
    {
      type: DynamicFormFieldTypes.select,
      name: 'province',
      label: 'providerNetwork.provinceInput',
      rules: {
        required: true
      },
      options: []
    },
    {
      type: DynamicFormFieldTypes.select,
      name: 'locality',
      label: 'providerNetwork.localityInput',
      rules: {
        required: true
      },
      options: []
    }
  ];

  constructor(
    private $modalCtrl: ModalController,
    private $miaAlert: MiaAlertService,
    private $miaModalResolver: MiaModalResolverService,
    private $miaLoading: MiaLoadingService,
    private $miaProvidersLoader: MiaProvidersLoaderService<Provider>,
    private $miaLogger: MiaLoggerService,
    private $miaGeolocation: MiaGeolocationService) { 

      console.log(this.action);
    }

  ngOnInit() {
    console.log(this.filter);
    this._loadProvidersManualFieldOptions(
      'establishment',
      'province'
    );
  }

  async onSelectChange(field: DynamicFormField) {
    const fromField = 'province';

    if (field.name !== fromField) {
      return;
    }

    const fieldValue = this.form.get(field.name).value;
    const targetField = 'locality';

    // Empty target field value before
    // populating it again with options
    this.form.get(targetField).setValue('');

    const options = await this.$miaProvidersLoader
      .getUniqueValuesByValueAndDependantKey(
        this.manualGeolocationFieldsProviders[fromField],
        fieldValue,
        this.manualGeolocationFieldsProviders[targetField]
      );

    const localityField = this.manualGeolocationFields.find(({ name }) => name === targetField);

    localityField.options = options.map(option => ({
      label: option,
      value: option
    }));
  }

  async openGeolocationUsingUserGPS() {
    const loading = await this.$miaLoading.present(
      'providerNetwork.geolocationLoading'
    );

    try {
      const { coords } = await this.$miaGeolocation.getCurrentPosition();
      const userCoords = new google.maps.LatLng(
        coords.latitude,
        coords.longitude
      );

      const allProviders = await this.$miaProvidersLoader.providersPromise;
      const providers: Provider[] = [];

      for (const provider of allProviders) {
        const distance = google.maps.geometry.spherical.computeDistanceBetween(
          userCoords,
          new google.maps.LatLng(
            parseFloat(provider.LATITUD),
            parseFloat(provider.LONGITUD)
          )
        )

        if (distance <= ProviderNetworkPage.MAX_PROVIDERS_DISTANCE) {
          providers.push(provider);
        }
      }

      await loading.dismiss();

      if (!providers.length) {
        return this.$miaAlert.openError(
          'providerNetwork.noProvidersFoundGPS'
        );
      }

      this.$miaModalResolver.open('providerNetworkMap', {
        componentProps: {
          userPosition: {
            lat: coords.latitude,
            lng: coords.longitude
          } as google.maps.LatLngLiteral,
          providers
        }
      });
    } catch (error) {
      this.$miaLogger.error('Geolocation error:', error);

      await loading.dismiss();
      this.$miaAlert.openError(
        'providerNetwork.geolocationError'
      );
    }
  }

  async openGeolocationUsingSpecificLocation() {
    const { value } = this.form;
    const providers = await this.$miaProvidersLoader
      .getProvidersByFieldConditions({
        'CATEGORIA MENU': this.filter,
        'TIPO DE SERVICIO': value.establishment,
        DEPARTAMENTO: value.province,
        CIUDAD: value.locality
      });
      
    if (!providers.length) {
      return this.$miaAlert.openError(
        'providerNetwork.noProvidersFound'
      );
    }

    //console.log (providers);
    this.$miaModalResolver.open('providerNetworkMap', {
      componentProps: {
        providers
      }
    });
  }

  closeModal() {
    this.$modalCtrl.dismiss();
  }

  private async _loadProvidersManualFieldOptions(...fieldNames: DynamicFormField['name'][]) {
    for (const fieldName of fieldNames) {
      const providerKey = this.manualGeolocationFieldsProviders[fieldName];

      if (!providerKey) {
        this.$miaLogger.log(
          `Field '${fieldName}' not found in providers`
        );
      }

      //const options = await this.$miaProvidersLoader.getUniqueValuesByKey(providerKey);
      //const options = await this.$miaProvidersLoader.getProvidersByFieldConditions({ 'CATEGORIA MENU': this.filter });
      const options = await this.$miaProvidersLoader
      .getUniqueValuesByValueAndDependantKey(
        this.manualGeolocationFieldsProviders['category'],
        this.filter,
        this.manualGeolocationFieldsProviders[fieldName]
      );

      const field = this.manualGeolocationFields.find(({ name }) => name === fieldName);

      field.options = options.map<DynamicFormOption>(option => ({
        label: option,
        value: option
      }));
    }
  }
}
