import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { IonButton } from '@ionic/angular';

@Component({
  selector: 'mia-button',
  templateUrl: './mia-button.component.html',
  styleUrls: ['./mia-button.component.scss']
})
export class MiaButtonComponent implements OnInit {

  @Input()
  color: IonButton['color'] = 'primary';

  @Input()
  fill: IonButton['fill'] = 'solid';

  @Input()
  size: IonButton['size'] = 'default';

  @Input()
  expand: IonButton['expand'];

  @Input()
  disabled: IonButton['disabled'] = false;

  @Output()
  press = new EventEmitter<MouseEvent>();

  @HostBinding('style')
  get expandStyle(): SafeStyle {
    let styles = '';

    switch (this.expand) {
      case 'full':
        styles = 'width: 100%;';
        break;

      case 'block':
        styles = 'display: block;';
        break;
    }

    return this.$sanitizer
      .bypassSecurityTrustStyle(styles);
  }

  constructor(
    private $sanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }

  @HostListener('click', ['$event'])
  onClick($event: MouseEvent): void {
    if (!this.disabled) {
      this.press.emit($event);
    }
  }
}
