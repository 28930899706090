import { InjectionToken } from '@angular/core';
import { DBConfig } from 'ngx-indexed-db';

import {
  MiaModalName,
  MiaModalComponent
} from './modal';
import {
  ActionObject,
  PredefinedAction
} from './action';
import {
  MiaPopoverComponent,
  MiaPopoverName
} from './popover';

export interface Config {
  /**
   * Logo name
   *
   * Note: must be in the `assets/icon` folder
   * and must have `svg` extension
   */
  logo: string;

  /**
   * Indexed-db module config
   */
  db: DBConfig;

  /**
   * URI pointing to assets folder
   */
  assetsUri: string;

  /**
   * Modals registration for "ModalResolverService"
   *
   * Default pre-defined modals are:
   *
   *  - history
   *  - contact
   */
  modals: { [K in MiaModalName]: MiaModalComponent };

  /**
   * Popovers registration for "PopoverResolverService"
   */
  popovers: { [K in MiaPopoverName]: MiaPopoverComponent };

  /**
   * Configuration for "AlertService"
   */
  alert: {
    errorIcon: string;
  };

  /**
   * Pre-defined actions
   *
   * Default pre-defined actions are:
   *
   *  - whatsapp
   *  - tel
   */
  actions: { [K in PredefinedAction]: ActionObject };
}

export const ConfigToken = new InjectionToken<Config>('MiaComponents.Config');
