export enum DynamicFormFieldTypes {
  text = 'text',
  number = 'number',
  email = 'email',
  tel = 'tel',
  select = 'select',
  textarea = 'textarea',
  amount = 'amount'
}

export interface DynamicFormOption {
  label: string;
  value: any;
}

export interface DynamicFormRules {
  amount?: boolean;
  email?: boolean;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
}

export interface DynamicFormField {
  name: string;
  label: string;
  type: DynamicFormFieldTypes;
  value?: string;
  floatingSelect?: boolean;
  rules?: DynamicFormRules;
  placeholder?: string;
  options?: DynamicFormOption[];
  // TODO:
  // helpText?: OpenAlertOptions['message'];
}
