import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormControl } from '@angular/forms';

import { SelectOption } from '../select';

@Component({
  selector: 'mia-select-item',
  templateUrl: './mia-select-item.component.html',
  styleUrls: ['./mia-select-item.component.scss']
})
export class MiaSelectItemComponent implements OnInit {

  @Input()
  label: string;

  @Input()
  floatingLabel = false;

  @Input()
  coloredLabel = false;

  @Input()
  control = new FormControl();

  @Input()
  options: SelectOption[];

  @Output()
  valueChange = new EventEmitter<CustomEvent<{ value: any }>>();

  constructor() { }

  ngOnInit(): void {
  }

}
