import { DatePipe } from '@angular/common';
import {
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import {
  DynamicFormField,
  DynamicFormFieldTypes,
  GeolocationData,
  MiaActionPerformerService,
  MiaAlertService,
  MiaHeaderComponent,
  MiaHistoryService,
  MiaLoadingService,
  MiaModalResolverService,
  OpenAlertOptions
} from 'src/mia-components/public-api';

import { FileConverterService } from '../file-converter.service';
import { CreateAssistData, MaiCreatorClientService } from '../mia-creator-client.service';
import { MaiViewerClientService } from '../mia-viewer-client.service';
import { PersonalData, PersonalDataService } from '../personal-data.service';
import contracts from '../../config/api/contract.json';
import { ModalService } from '../modal.service';
import { assistanceService } from '../api/assistance.service';

@Component({
  selector: 'app-home-doctor',
  templateUrl: './home-doctor.page.html',
  styleUrls: ['./home-doctor.page.scss'],
})

export class HomeDoctorPage implements OnInit {
  @Input() pageReference: string;

  form = new FormGroup({
    comments: new FormControl()
  });

  fields: DynamicFormField[] = [];
  userData: PersonalData;
  fileUrls = new WeakMap<File, SafeUrl>();
  fileEntries: Array<File | undefined> = [];

  private geolocationData: GeolocationData;

  @ViewChild('miaHeader')
  private miaHeaderComponent: MiaHeaderComponent;

  private userMarker: google.maps.Marker;

  constructor(
    private $modalCtrl: ModalController,
    private $miaModalResolver: MiaModalResolverService,
    private $sanitizer: DomSanitizer,
    private $personalData: PersonalDataService,
    private $miaLoading: MiaLoadingService,
    private $maiViewerClient: MaiViewerClientService,
    private $maiCreatorClient: MaiCreatorClientService,
    private $miaAlert: MiaAlertService,
    private $translate: TranslateService,
    private $miaHistory: MiaHistoryService,
    private $fileConverter: FileConverterService,
    private $miaActionPerformer: MiaActionPerformerService,
    private $modalService: ModalService,
    private $assistanceService: assistanceService) { }

  async ngOnInit() {
    const geolocationModal = await this.$miaModalResolver.open('geolocation');

    //await this._loadFields();

    geolocationModal
      .onDidDismiss<GeolocationData>()
      .then(({ data }) => {
        if (data) {
          this.geolocationData = data;
          return this._loadFields();
        }

        setTimeout(() => {
          this.closeModal();
        }, 100);
      });

    this.userData = await this.$personalData.getLastEntry();
  }

  private async _loadFields() {
    const fieldsLoading = await this.$miaLoading.present(
      'homeDoctor.loadingFieldsText'
    );

    let idClient = 'br';
    if (window.location.pathname.split('/').slice(1)) {
      var arrayParams = window.location.pathname.split('/').slice(1);
      if (arrayParams.length > 0) {
        idClient = arrayParams[0];
      }
    }

    try {

      var objContract = {};
      for (let ctr of contracts) {
        if (ctr.cliente === idClient) {
          objContract = ctr;
        }
      }

      let clienteId = objContract["idClient"];
      let contrato: any = objContract['servicesBySlug'][this.pageReference];

      const result = await this.$assistanceService.infadicional(
        clienteId,
        contrato.codcontra,
        contrato.suplem,
        contrato.codcausa,
        contrato.codriesgo,
        contrato.codgarant,
        contrato.codservicio
      ).then((result: any) => {
        if (result) {
          let campos = {
            "data": {
              "infadicionales": result
            }
          }

          this.fields = campos.data.infadicionales.map(attr => {
            let isNumberAttr = false;
            let isTextAttr = false;
            let isSelectAttr = false;
            let isDate = false;

            if (attr.format === 'NUMERO') {
              isNumberAttr = true;

            } else if (attr.format === 'ALFNUM' && !attr.allowedValues) {
              isTextAttr = true;

            } else if (attr.format === 'ALFNUM' && attr.allowedValues) {
              isSelectAttr = true;
            }

            const field: DynamicFormField = {
              name: attr.field,
              label: attr.description,
              type: DynamicFormFieldTypes[isNumberAttr ? 'number' : (isTextAttr ? 'text' : (isSelectAttr ? 'select' : 'datetime'))],
              rules: {
                required: true
              }
            };

            if (!isTextAttr && !isNumberAttr) {
              field.floatingSelect = true;
              field.options = attr.allowedValues.map(value => ({
                label: value,
                value
              }));
            }

            return field
          });
        }
      });

    } catch (error) {
      this.$miaAlert.openError(
        'homeDoctor.errorLoadingFieldsText'
      );
    }

    fieldsLoading.dismiss();
  }

  onFileSelect({ target }: Event, index: number) {
    const $input = (target as HTMLInputElement);
    const file = $input.files[0];

    this.fileEntries[index] = file;
    this.fileUrls.set(
      file,
      this.$sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file))
    );

    $input.value = '';
  }

  getFileURL(file: File | undefined) {
    return this.fileUrls.get(file);
  }

  addFileEntry() {
    this.fileEntries.unshift(undefined);
  }

  removeFileEntry(index: number) {
    this.fileUrls.delete(this.fileEntries[index]);
    this.fileEntries.splice(index, 1);
  }

  closeModal() {
    this.$modalCtrl.dismiss();
  }

  async onSaveData() {
    const saveDate = new Date();
    const datePipe = new DatePipe('es');
    var DATE_FORMAT = 'yyyy-MM-dd HH:mm:ss';
    const location = this.geolocationData.location.geometry?.location.toJSON();
    const createAssistLoading = await this.$miaLoading.present(
      'homeDoctor.creatingAssistText'
    );

    try {
      const {
        comments,
        ...variableAttributes
      } = this.form.value;

      var flagArray = false;
      for (let item of Object.keys(variableAttributes)) {
        if (item === 'Y51' || item === 'Y52' || item === 'ST1') {
          variableAttributes[item] = datePipe.transform(saveDate, 'yyyy-MM-dd HH:mm:ss');
        } else if (item === 'Y44') {
          variableAttributes[item] = datePipe.transform(saveDate, 'yyyy-MM-dd');
        }

        if (Array.isArray(variableAttributes[item])) {
          flagArray = true;
        }
      }

      let idClient = 'br';
      if (window.location.pathname.split('/').slice(1)) {
        var arrayParams = window.location.pathname.split('/').slice(1);
        if (arrayParams.length > 0) {
          idClient = arrayParams[0];
        }
      }

      var objContract = {};
      for (let ctr of contracts) {
        if (ctr.cliente === idClient) {
          objContract = ctr;
        }
      }

      let contract = objContract['servicesBySlug'][this.pageReference];

      var createAssistData = {
        'crearAsistencia': {
          'mseCrearAsistencia': {
            cabecera: {
              AMA: 'BR',
              CLIENT: parseInt(objContract['idClient']),
              ID: contract['idClient'],
              FILE: `mia-${datePipe.transform(saveDate, 'yyyyMMddHHmmssSSS')}`
            },
            atributosFijos: {
              DATE: datePipe.transform(saveDate, DATE_FORMAT),
              NAME: this.userData.firstName,
              SURNAME1: this.userData.fatherLastName,
              SURNAME2: '',
              POLICY: 'miahealthbr',
              RISK: parseInt(contract["codriesgo"]),
              GUARANTEE: contract["codgarant"],
              CAUSE: parseInt(contract["codcausa"]),
              SERVICE: parseInt(contract["codservicio"]),
              COUNTRY1: 'BR',
              COUNTRY2: 'BR',
              LOCATION: `${this.geolocationData.location.formatted_address}${this.geolocationData.property?.trim() ? ` ${this.geolocationData.property}` : ''}`,
              TELEPHONE: this.userData.phoneNumber,
              DNI: this.userData.documento,
              SERVICE_DATE: datePipe.transform(new Date(), DATE_FORMAT),
              MSG_CLIENT: '-',
              COMMENTS: comments,
              EMAIL: this.userData.email,
              REIMBURSEMENT: 'N',
              ORIGEN_LAT: location.lat,
              ORIGEN_LON: location.lng,
              DESTINO_LAT: location.lat,
              DESTINO_LON: location.lng,
              supplementId: contract["suplem"],
              codclient: contract["codcontra"]
            },
            atributosVariables: Object.keys(variableAttributes).map(attrName => ({
              attributeCd: attrName,
              value: variableAttributes[attrName]
            }))
          }
        }
      };

      if (this.fileEntries.length) {
        createAssistData['crearAsistencia']['mseCrearAsistencia']['documentos'] = await Promise.all(
          this.fileEntries.map(async file => ({
            TYPE: 20,
            FILE_NAME: file.name,
            ADDITIONAL_INF: '',
            DOC: await this.$fileConverter.toBase64(file)
          }))
        );
      }

      if (flagArray) {
        var arrayInfo = [];
        for (let item of Object.keys(variableAttributes)) {
          if (Array.isArray(variableAttributes[item])) {
            for (let d of variableAttributes[item]) {
              arrayInfo.push({
                etiqueta: item,
                valor: d
              });
            }
          } else {
            arrayInfo.push({
              etiqueta: item,
              valor: variableAttributes[item]
            });
          }
        }

        createAssistData['crearAsistencia']['mseCrearAsistencia']['atributosVariables'] = arrayInfo;
      }

      let amaError: any;
      let amaAssist: number;

      //await this.$maiCreatorClient.createAssist(createAssistData, token);

      this.$maiCreatorClient.createAsis(createAssistData).then(
        (result) => {
          amaAssist = result['crearAsistenciaResponse'].mssCrearAsistencia.AMA_ASSIST;
          amaError = result['crearAsistenciaResponse'].mssCrearAsistencia.AMA_ERROR;
          this.procesarRespuesta(contract['idClient'], amaAssist, amaError);
          createAssistLoading.dismiss();
        }
      );

    } catch (error) {
      this.$miaAlert.openError(
        'homeDoctor.createAssistUnhandledError'
      );
    }

    createAssistLoading.dismiss();
  }

  async procesarRespuesta(idClient, amaAssist, amaError) {
    const alertOptions: OpenAlertOptions = {
      icon: 'comprobado',
      message: []
    };

    var flag = true;
    alertOptions.message = [];

    if (amaError.AMA_ERROR) {
      const { ERR_TEXT } = amaError.AMA_ERROR;
      //alertOptions.icon += '-invertido';
      alertOptions.inverted = true;
      alertOptions.message = [
        ...this.$translate.instant('homeDoctor.createAssistError'),
        /* Array.isArray(ERR_TEXT)
          ? `<ul>${ERR_TEXT.map(error => `<li>${error}</li>`).join('')}</ul>`
          : ERR_TEXT*/
      ];
    } else if (amaAssist) {
      alertOptions.message  = this.$translate.instant('homeDoctor.createAssistSuccess');
      alertOptions.message += ("<br><p style='color: #000;'>Número de asistencia: <span style='font-weight: bold;'>" + amaAssist + "</span></p>");
      alertOptions.message += this.$translate.instant('homeDoctor.createAssistSuccessDos');
 
      // for (let i = 0; i < alertOptions.message.length; i++) {
      //   alertOptions.message[i] = this.$translate.parser
      //     .interpolate(alertOptions.message[i], { amaAssist });
      // }

      await this.$miaHistory.addEntry({
        type: 'assistance',
        date: new Date(),
        data: {
          id: amaAssist,
          icon: 'botiquin_de_primeros_auxilios'
          // icon: this.lastNavigationItem.icon
        }
      });
    } else {
      alertOptions.icon += '-invertido';
      alertOptions.inverted = true;
      alertOptions.message = this.$translate
        .instant('homeDoctor.createAssistInfo');
    }

    const alert = await this.$miaAlert.open({
      ...alertOptions,
      buttons: [amaError.ERR_TEXT ? 'Chamar' : 'Aceitar']
    });

    alert
      .onDidDismiss()
      .then(() => {
        if (amaError.ERR_TEXT) {
          this.$miaActionPerformer.perform('homeDoctorAssistErrorTel');
        }

        this.miaHeaderComponent.goHome();
      });
  }


  private async _getCurrentPlace(): Promise<google.maps.GeocoderResult> {
    var geocoder = new google.maps.Geocoder();
    var geocoderRequest: google.maps.GeocoderRequest = {
      location: this.userMarker.getPosition()
    };

    return new Promise((resolve, reject) => {
      geocoder.geocode(geocoderRequest, ([result], status) => {
        if (status !== google.maps.GeocoderStatus.OK) {
          reject(status);
        } else {
          resolve(result);
        }
      });
    });
  }
}
