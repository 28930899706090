import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, Input, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { DynamicFormField, DynamicFormFieldTypes, MiaAlertService } from 'src/mia-components/public-api';
import { PersonalData, PersonalDataService } from '../personal-data.service';
import { TelemedicinaService, dataCadastro, dataAtendimiento } from '../telemedicina.service';
import { MaiCreatorClientService, CreateAssistData } from '../mia-creator-client.service';
import { OpenAlertOptions } from 'src/mia-components/public-api';

export interface AssistAttributes {
  appointment: Date | '~now';
  comments: string;
  [key: string]: any;
}

@Component({
  selector: 'app-telemedicina',
  templateUrl: './telemedicina.page.html',
  styleUrls: ['./telemedicina.page.scss'],
})
export class TelemedicinaPage implements OnInit {
  userData: PersonalData;
  form = new FormGroup({});
  propertyDetailControl = new FormControl(null, [
    Validators.required
  ]);
  loading: boolean;
  dataCadastro: dataCadastro;
  dataAtendimiento: dataAtendimiento;
  @Input()
  assistAttributes: AssistAttributes;

  telemedicinaFields: DynamicFormField[] = [
    {
      type: DynamicFormFieldTypes.text,
      name: 'cep',
      label: 'telemedicina.cepInput',
      //value: '05159-160',
      rules: {
        required: true
      },
    },
    {
      type: DynamicFormFieldTypes.text,
      name: 'rua',
      label: 'telemedicina.ruaInput',
      //value: 'Praça Erva Santa',
      rules: {
        required: true
      },
    },
    {
      type: DynamicFormFieldTypes.number,
      name: 'numero',
      label: 'telemedicina.numeroInput',
      //value: '25',
      rules: {
        required: true
      },
    },
    {
      type: DynamicFormFieldTypes.text,
      name: 'bairro',
      label: 'telemedicina.bairroInput',
      //value: 'Jardim Mutinga',
      rules: {
        required: true
      },
    },
    {
      type: DynamicFormFieldTypes.text,
      name: 'cidade',
      label: 'telemedicina.cidadeInput',
      //value: 'São Paulo',
      rules: {
        required: true
      },
    },
    {
      type: DynamicFormFieldTypes.text,
      name: 'estado',
      label: 'telemedicina.estadoInput',
      //value: 'SP',
      rules: {
        required: true
      },
    },
    {
      type: DynamicFormFieldTypes.select,
      name: 'sexo',
      label: 'telemedicina.sexoInput',
      rules: {
        required: true
      },
      options: [
        {
          label: "Mujer",
          value: "M"
        },
        {
          label: "Hombre",
          value: "H"
        }
      ]
    },
    {
      type: DynamicFormFieldTypes.text,
      name: 'data_nascimento',
      label: 'telemedicina.dataNascimentoInput',
      //value: '26/12/1986',
      rules: {
        required: true
      },
    },
    {
      type: DynamicFormFieldTypes.text,
      name: 'senha',
      label: 'telemedicina.senhaInput',
      //value: 'A12345678.',
      rules: {
        required: true
      },
    },
    {
      type: DynamicFormFieldTypes.text,
      name: 'complemento',
      label: 'telemedicina.complementoInput',
    },

    {
      type: DynamicFormFieldTypes.number,
      name: 'peso',
      label: 'telemedicina.pesoInput',
      //value: '86',
      rules: {
        required: true
      },
    },
    {
      type: DynamicFormFieldTypes.number,
      name: 'altura',
      label: 'telemedicina.alturaInput',
      //value: '183',
      rules: {
        required: true
      },
    },
    {
      type: DynamicFormFieldTypes.text,
      name: 'sintoma',
      label: 'telemedicina.sintomaInput',
      //value: 'Sintoma de prueba',
      rules: {
        required: true
      },
    }
  ];

  private userMarker: google.maps.Marker;

  constructor(
    private $modalCtrl: ModalController,
    private $telemedicinaService: TelemedicinaService,
    private $maiCreatorClient: MaiCreatorClientService,
    private $personalData: PersonalDataService,
    private $miaAlert: MiaAlertService,
  ) { }

  async ngOnInit() {
    this.userData = await this.$personalData.getLastEntry();
  }

  closeModal() {
    this.$modalCtrl.dismiss();
  }

  async saveData() {
    this.loading = true;
    this.$telemedicinaService.authentication().subscribe(resp => {
      let token = resp.token;
      this.dataCadastro = {
        cpf: "" + this.userData.documento + "",
        nome: this.userData.firstName,
        sobrenome: this.userData.fatherLastName,
        email: this.userData.email,
        celular: this.userData.phoneNumber,
        cep: this.form.get('cep').value,
        rua: this.form.get('rua').value,
        numero: this.form.get('numero').value,
        bairro: this.form.get('bairro').value,
        cidade: this.form.get('cidade').value,
        estado: this.form.get('estado').value,
        sexo: this.form.get('sexo').value,
        data_nascimento: this.form.get('data_nascimento').value,
        senha: this.form.get('senha').value,
        complemento: this.form.get('complemento').value,
      }

      this.dataAtendimiento = {
        codigo_interno: null,
        dependente_id: -41954324,
        peso: this.form.get('peso').value,
        altura: this.form.get('altura').value,
        sintoma: this.form.get('sintoma').value,
        origem: "reprehenderit",
        triagem: "sed esse amet velit Duis"
      }

      this.$telemedicinaService.cadastroPaciente(this.dataCadastro, token).subscribe(respCadastro => {
        if (respCadastro.token_user) {
          let tokenUser = respCadastro.token_user;

          this.$telemedicinaService.atendimientoPaciente(this.dataAtendimiento, token, tokenUser).subscribe(respAtendimiento => {
            if (respAtendimiento.url_teleconsulta) {
              let urlAtendimiento = respAtendimiento.url_teleconsulta;
              this.createAssist();
              this.loading = false;
              this.openPopupFromAnchor(urlAtendimiento);
            } else {
              this.loading = false;
            }
          })
        } else {
          this.loading = false;
        }
      }, err => {
        const alertOptions: OpenAlertOptions = {
          icon: '',
          message: err.error.data.msg_erro
        };
  
        this.loading = false;
        
        this.$miaAlert.open({
          ...alertOptions,
          buttons: [
            {
              text: 'OK',
              role: 'cancel'
            }
          ]
        });
      })
    });
  }

  openPopupFromAnchor(href) {
    const anchor = document.createElement('a');
    anchor.href = href;
    anchor.target = '_blank';
    anchor.click();
  }

  async createAssist() {
    var saveDate = new Date();
    var datePipe = new DatePipe('es');
    var DATE_FORMAT = 'ddMMyyyy HHmm';

    try {
      var personalData = this.userData;
      var place = await this._getCurrentPlace();
      var { token } = await this.$maiCreatorClient.login();
      var {
        appointment,
        comments,
        ...variableAttributes
      } = this.assistAttributes;

      var flagArray = false;
      for (let item of Object.keys(variableAttributes)) {
        if (item === 'Y51' || item === 'Y52' || item === 'ST1') {
          variableAttributes[item] = datePipe.transform(saveDate, 'ddMMyyyy HHmmss');
        } else if (item === 'Y44') {
          variableAttributes[item] = datePipe.transform(saveDate, 'ddMMyyyy');
        }

        if (Array.isArray(variableAttributes[item])) {
          flagArray = true;
        }
      }

      var location = place.geometry?.location.toJSON();
      var createAssistData: CreateAssistData = {
        cabecera: {
          AMA: 'BR',
          CLIENT: '257',
          ID: '40001114692',
          FILE: `mia-${datePipe.transform(saveDate, 'yyyyMMddHHmmssSSS')}`
        },
        atributosFijos: {
          DATE: datePipe.transform(saveDate, DATE_FORMAT),
          NAME: personalData.firstName,
          SURNAME1: personalData.fatherLastName,
          SURNAME2: '',
          POLICY: '',
          RISK: 200,
          GUARANTEE: 'B0020',
          CAUSE: 100001,
          SERVICE: 5210,
          BREAKDOWN: '',
          COUNTRY1: 'BR',
          ZONE1: '',
          STREET: '',
          LOCATION: `${place.formatted_address}${this.propertyDetailControl.value?.trim() ? ` ${this.propertyDetailControl.value}` : ''}`,
          ORIGEN_LAT: location.lat,
          ORIGEN_LON: location.lng,
          COUNTRY2: 'BR',
          ZONE2: '',
          TELEPHONE: personalData.phoneNumber,
          EMAIL: personalData.email,
          AMOUNT: '1',
          SERVICE_DATE: datePipe.transform((!appointment || appointment === '~now') ? new Date() : appointment, DATE_FORMAT),
          MSG_CLIENT: '-',
          COMMENTS: comments,
          CURRENCY: 'BRN'
        },
        atributosVariables: []
      };

      var {
        crearAsistenciaResponse: {
          mssCrearAsistencia: {
            AMA_ASSIST,
            AMA_SERVICE,
            AMA_ERROR
          }
        }
      } = await this.$maiCreatorClient.createAssist(createAssistData, token);
    } catch (error) {
      console.error(error);
    }
  }

  private async _getCurrentPlace(): Promise<google.maps.GeocoderResult> {
    var geocoder = new google.maps.Geocoder();
    var geocoderRequest: google.maps.GeocoderRequest = {
      location: this.userMarker.getPosition()
    };

    return new Promise((resolve, reject) => {
      geocoder.geocode(geocoderRequest, ([result], status) => {
        if (status !== google.maps.GeocoderStatus.OK) {
          reject(status);
        } else {
          resolve(result);
        }
      });
    });
  }
}
