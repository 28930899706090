import { Injectable } from '@angular/core';

import {
  Template,
  TemplateVarIdent,
  TemplateVarProcessor,
  TemplateVars
} from './template-processor';

export const TEMPLATE_IDENT_VAR_REGEX = /{([^}]+)}/g;
export function defaultTemplateVarProcessor(value: any): any {
  return value;
}

@Injectable({
  providedIn: 'root'
})
export class MiaTemplateProcessorService {

  constructor() { }

  process(
    template: Template,
    templateVars: TemplateVars,
    varProcessor: TemplateVarProcessor = defaultTemplateVarProcessor
  ): string {
    return template.replace(
      TEMPLATE_IDENT_VAR_REGEX,
      (_: string, varIdent: TemplateVarIdent) => varProcessor(templateVars[varIdent], varIdent)
    );
  }
}
