import {
  Inject,
  Injectable
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalOptions } from '@ionic/core';

import {
  Config,
  ConfigToken
} from './config-token';
import {
  MiaModalComponent,
  MiaModalName
} from './modal';

@Injectable({
  providedIn: 'root'
})
export class MiaModalResolverService {

  constructor(
    @Inject(ConfigToken)
    private $config: Config,
    private $modalCtrl: ModalController) { }

  resolve(modal: MiaModalName): MiaModalComponent {
    return this.$config.modals[modal];
  }

  async open(modal: MiaModalName, options: Omit<ModalOptions, 'component'> = {}): Promise<HTMLIonModalElement> {
    const modalInstance = await this.$modalCtrl.create({
      component: this.resolve(modal),
      ...options
    });

    await modalInstance.present();
    return modalInstance;
  }
}
