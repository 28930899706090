import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

declare var externalMeeting;
declare var closeMeeting;

@Component({
  selector: 'app-meeting-doctors',
  templateUrl: './meeting-doctors.page.html',
  styleUrls: ['./meeting-doctors.page.scss'],
})
export class MeetingDoctorsPage implements OnInit {

  constructor(private $modalCtrl: ModalController) { }

  ngOnInit() {
    externalMeeting();
  }

  closeModal() {
    closeMeeting();
    this.$modalCtrl.dismiss();
  }

}