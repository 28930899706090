import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';
import { PharmaClientService } from '../pharma.service';
import { PersonalData, PersonalDataService } from '../personal-data.service';
import { MiaAlertService } from 'src/mia-components/public-api';

@Component({
  selector: 'app-rede-credenciada',
  templateUrl: './rede-credenciada.page.html',
  styleUrls: ['./rede-credenciada.page.scss'],
})

export class RedeCredenciadaPage implements OnInit {
  loading: boolean;
  userData: PersonalData;
  redeUrl: any;
  tipoUrl: number;

  @Input() filter: string;

  constructor(
    private $modalCtrl: ModalController,
    private domSanitizer: DomSanitizer,
    private $pharma: PharmaClientService,
    private $personalData: PersonalDataService,
    private $miaAlert: MiaAlertService
  ) { }

  async ngOnInit() {
    this.loading = true;
    
    if (this.filter === 'consultas') {
      this.tipoUrl = 1;
    
    } else {
      this.tipoUrl = 2;
    }

    this.userData = await this.$personalData.getLastEntry();
    this.$pharma.redeCredenciada(this.tipoUrl, this.userData.documento).toPromise()
    .then((result) => {
      if (result['url']) {
        this.redeUrl = this.transform(result['url']);
        this.loading = false;
      
      } else {
        this.loading = false;

      }
    }).catch((error) => {
      this.loading = false;
      this.$miaAlert.openAlert(
        'CPF ou número de contrato informado não é válido ou não está ativo'
      );
    })
  }

  transform(url) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

  closeModal() {
    this.$modalCtrl.dismiss();
  }

}
