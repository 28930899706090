import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
//import { PersonalData, PersonalDataService } from './personal-data.service';

interface TokenResult {
  access_token: string;
}

interface GetStatesResult {
  estados: [
    {
      nome: string;
      codigo: string;
    }
  ]
}

interface GetCitiesResult {
  cidades: [
    {
      nome: string;
      codigo?: number;
    }
  ]
}

interface GetDistrictsResult {
  bairros: [
    {
      nome: string;
    }
  ]
}


@Injectable({
  providedIn: 'root'
})
export class PharmaClientService {
  //userData: PersonalData;


  credentials = {
    // url: 'https://restqa.epharma.com.br',
    // user: '5b515698-ef94-49e4-94a8-86956dcb8cab',
    // pass: '5b515698-ef94-49e4-94a8-86956dcb8cab'

    user: '0e68529e-4d7c-459f-ac40-f1879478fb2e',
    pass: '0e68529e-4d7c-459f-ac40-f1879478fb2e',
    url: 'https://rest.epharma.com.br'
  };

  constructor(
    private $http: HttpClient,
    //private $personalData: PersonalDataService
  ) { }

  login() {

    let headers2send: any = {
      'content-type': 'application/x-www-form-urlencoded'
    };

    // let headers2send = new HttpHeaders();
    // headers2send = headers2send.append('Content-Type', 'application/x-www-form-urlencoded');
    // headers2send = headers2send.append('Content-Type', 'application/x-www-form-urlencoded');
    // //headers2send = headers2send.append('Access-Control-Allow-Origin', '*');
    // //headers2send = headers2send.append('Access-Control-Allow-credentials', 'true');
    // // headers2send = headers2send.append('Access-Control-Allow-methods', 'POST');
    // headers2send = headers2send.append('Accept', '*');

    let formTXT = "grant_type=password&client_id=etgtIB0O43saFI24MRkm2o3mr3l35UY&client_secret=etgtIB0O43saFI24MRkm2o3mr3l35UY&username=0e68529e-4d7c-459f-ac40-f1879478fb2e&password=0e68529e-4d7c-459f-ac40-f1879478fb2e"

    return this.$http
      .post<TokenResult>(this._getURL('oauth/token'), formTXT, { headers: headers2send },)
      .toPromise();
  }

  getStates(idplan: string, token: string) {
    const headers2send = {
      Authorization: `Bearer ${token}`,
    };

    return this.$http
      .get<GetStatesResult>(
        this._getURL('api/RedeCredenciada/Estados'),
        {
          headers: headers2send,
          params: { plano: idplan }
        }
      )
      .toPromise();
  }

  getCities(idplan: string, idstate: string, token: string) {
    const headers2send = {
      Authorization: `Bearer ${token}`,
    };

    return this.$http
      .get<GetCitiesResult>(
        this._getURL('api/RedeCredenciada/Cidades'),
        {
          headers: headers2send,
          params: { plano: idplan, uf: idstate }
        }
      )
      .toPromise();
  }

  getDistricts(idplan: string, idstate: string, idcity: string, token: string) {
    const headers2send = {
      Authorization: `Bearer ${token}`,
    };

    return this.$http
      .get<GetDistrictsResult>(
        this._getURL('api/RedeCredenciada/Bairros'),
        {
          headers: headers2send,
          params: { plano: idplan, uf: idstate, cidade: idcity }
        }
      )
      .toPromise();
  }


  getRede(idplan: string, idstate: string, idcity: string, barrio: string, token: string) {
    const headers2send = { Authorization: `Bearer ${token}` };

    return this.$http.get(this._getURL('api/RedeCredenciada'),
      {
        headers: headers2send,
        params: { plano: idplan, uf: idstate, cidade: idcity, bairro: barrio }
      }
    )
      .toPromise();
  }

  private _getURL(path: string) {
    return `${this.credentials.url}/${path}`;
  }

  redeCredenciada(tipo, cpf) {
    return this.$http
      .get('https://ddt8urmaeb.execute-api.us-east-1.amazonaws.com/prd-v1/rms1/rede-credenciada/url',
        {
          headers: {
            'x-api-key': '0JsM98JpHm3vjFCUlni8n2LO8pHGYCZl8QsOeMJq',
            'Content-Type': 'application/json'
          },
          params: {
            tipoSolicitacao: tipo,
            idContratoPlano: "100577864",
            modoIframe: "1",
            cpf: cpf
          }
        }
      );
  }

  phygitalAuthenticate() {
    return this.$http
      .get('https://api.epharma.com.br/Authentication/api/v1/OAuth/Authenticate',
        {
          headers: {
            'username': '0e68529e-4d7c-459f-ac40-f1879478fb2e',
            'password': ' 0e68529e-4d7c-459f-ac40-f1879478fb2e',
            'client_id': 'etgtIB0O43saFI24MRkm2o3mr3l35UY'
          }
        }
      );
  }

  phygitalToken(token, cpf) {

    const headers2send = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    };

    var obj = {
      clientId: "etgtIB0O43saFI24MRkm2o3mr3l35UY",
      beneficiaryId: cpf,
      partner: "nome"
    }
    var objJson = JSON.stringify(obj);
    var content = btoa(objJson).toString();
    var guid = (this.S4() + this.S4() + "-" + this.S4() + "-4" + this.S4().substr(0, 3) + "-" + this.S4() + "-" + this.S4() + this.S4() + this.S4());

    let tokenKey = guid;
    let contenido = content;

    let body = {
      "tokenTypeId": 3,
      "key": tokenKey,
      "content": contenido
    }

    return this.$http.post('https://api.epharma.com.br/Common/api/v1/Token', body, { headers: headers2send });
  }

  S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }

  // async obtenerCPF() {
  //   this.userData = await this.$personalData.getLastEntry();
  //   return this.userData.documento;
  // }
}
