import {
  Inject,
  Injectable
} from '@angular/core';

import {
  Config,
  ConfigToken
} from './config-token';

export type IconExtension = 'svg' | 'png';
export type ImageExtension = 'jpg' | 'png';

@Injectable({
  providedIn: 'root'
})
export class AssetUriResolverService {

  constructor(
    @Inject(ConfigToken)
    private $config: Config) { }

  resolveIconUri(icon: string, ext: IconExtension = 'svg'): string {
    return this.resolveUri(`icon/${icon}.${ext}`);
  }

  resolveImageUri(image: string, ext: ImageExtension): string {
    return this.resolveUri(`img/${image}.${ext}`);
  }

  resolveProvidersFileUri(): string {
    return this.resolveUri('providersV3.xlsx');
  }

  resolveUri(path: string): string {
    return `${this.$config.assetsUri.replace(/\/$/, '')}/${path}`;
  }
}
