import {
  Component,
  Input,
  OnInit
} from '@angular/core';
import { ModalController } from '@ionic/angular';

import {
  HistoryEntry,
  MiaHistoryModalProps
} from '../history';
import { MiaHistoryService } from '../mia-history.service';

@Component({
  selector: 'mia-history-modal',
  templateUrl: './mia-history-modal.component.html',
  styleUrls: ['./mia-history-modal.component.scss']
})
export class MiaHistoryModalComponent implements OnInit, MiaHistoryModalProps {

  @Input()
  title = 'mia.historyModal.title';

  @Input()
  iconsByItemType = {
    tel: 'telefono',
    whatsapp: 'telefono_inteligente_1'
  };

  items: HistoryEntry[] = [];

  constructor(
    private $modalCtrl: ModalController,
    private $miaHistory: MiaHistoryService) { }

  ngOnInit(): void {
    this.loadEntries();
  }

  closeModal(): void {
    this.$modalCtrl.dismiss();
  }

  private async loadEntries(): Promise<void> {
    this.items = await this.$miaHistory.getEntries();
  }
}
